import {
  Button,
  Checkbox,
  Group,
  Paper,
  SimpleGrid,
  Stack,
  Title,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconTrash } from '@tabler/icons-react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  AddConsoleForm,
  TransformedValues,
} from '../../components/add-console-form/add-console-form';
import { ConsoleCard } from '../../components/console-card/console-card';
import { useManageConsoles } from '../../data-access/hooks/use-manage-consoles/manage-consoles';
import { useConnectivityQueries } from '../../data-access/queries/connectivity';

import { ErrorPage } from './page.error';
import classes from './page.module.css';
import { PendingPage } from './page.pending';

import { ActionsMenu, ActionsMenuItem, FullMainHeight } from '@ui';

export function ConnectivityPage() {
  const { t } = useTranslation();
  const connectivityQueries = useConnectivityQueries();
  const [selectedTerminals, setSelectedTerminals] = useState<string[]>([]);
  const [opened, actions] = useDisclosure();
  const connectivity = useSuspenseQuery(connectivityQueries.all());
  const manageConsoles = useManageConsoles();

  const handleAddConsole = (value: TransformedValues) => {
    manageConsoles.mutate(
      {
        console_id: value.console_id,
        action: 'add',
      },
      {
        onSuccess: () => {
          actions.close();
          manageConsoles.reset();
        },
      },
    );
  };

  const handleRemoveConsole = () => {
    const ids = selectedTerminals.map((id) => Number(id));
    console.log({ ids });
    ids.forEach((id) => {
      manageConsoles.mutate(
        {
          console_id: id,
          action: 'remove',
        },
        {
          onSuccess: () => {
            manageConsoles.reset();
          },
        },
      );
    });
  };

  const menuActions: ActionsMenuItem[] = [
    {
      type: 'action',
      label: t('common.delete'),
      icon: IconTrash,
      color: 'red',
      onClick: handleRemoveConsole,
    },
  ];

  return (
    <FullMainHeight.Root>
      <FullMainHeight.Content className={classes.content}>
        <Stack p="md" pos="relative">
          <Paper>
            <Group justify="space-between">
              <Title order={2}>{t('connectivity.consoles-title')}</Title>
              <Group>
                {/* <Button onClick={actions.open}>{t('common.add')}</Button> */}
                {/* <ActionsMenu items={menuActions}>
                  <ActionsMenu.Button />
                </ActionsMenu> */}
              </Group>
            </Group>
          </Paper>
          <Checkbox.Group
            onChange={setSelectedTerminals}
            value={selectedTerminals}
          >
            <SimpleGrid
              cols={{
                base: 1,
                sm: 2,
                md: 3,
                lg: 4,
              }}
            >
              {connectivity.data?.console.map((console) => (
                <ConsoleCard
                  id={console.id.toString()}
                  image={console.thumbnail}
                  key={console.name}
                  link={console.tutorial || '#'}
                  manufacturer={console.manufacturer}
                  name={console.name}
                />
              ))}
            </SimpleGrid>
          </Checkbox.Group>

          <AddConsoleForm
            loading={manageConsoles.isPending}
            onClose={actions.close}
            onSubmit={handleAddConsole}
            opened={opened}
          />
        </Stack>
      </FullMainHeight.Content>
    </FullMainHeight.Root>
  );
}

ConnectivityPage.Error = ErrorPage;
ConnectivityPage.Pending = PendingPage;

export default ConnectivityPage;
