import { centroid } from '@turf/centroid';
import { Expression } from 'mapbox-gl';
import { Layer, Source } from 'react-map-gl';

import { FieldsFeatureCollection } from '@fields/data-access';

export const PLOTS_LAYER_ID = 'plots-layer';
// import { PlotFeatureCollection } from '@data-access';

/* eslint-disable-next-line */
export interface PlotsLayerProps {
  data: FieldsFeatureCollection;
  id?: string;
  minZoom?: number;
  outline?: boolean;
  colorPolygon?: string;
  activePlotIds?: number[];
}

const polygonOutlineLayerStyle = {
  type: 'line' as const,
  paint: {
    'line-color': '#000',
    'line-width': 3,
  },
};

export function PlotsLayer(props: PlotsLayerProps) {
  const { colorPolygon, activePlotIds, ...restProps } = props;
  const polygonLayerStyle = {
    type: 'fill' as const,
    paint: {
      'fill-color': colorPolygon || (['get', 'color'] as Expression),
      'fill-opacity': 0.5,
    },
  };

  const { id = PLOTS_LAYER_ID, minZoom = 0, outline = false } = props;

  const plotCenterFeatureCollection = {
    type: 'FeatureCollection',
    features: props.data.features.map((feature) => {
      return {
        type: 'Feature',
        geometry: centroid(feature.geometry).geometry,
        properties: {
          name: feature.properties.name,
        },
      };
    }),
  };

  return (
    <>
      <Source data={props.data} id="plots-data" type="geojson">
        {!outline ? (
          <Layer id={id} minzoom={minZoom} {...polygonLayerStyle} />
        ) : null}
        <Layer
          id={`${id}_outline`}
          minzoom={minZoom}
          {...polygonOutlineLayerStyle}
        />
      </Source>
      <Source
        data={plotCenterFeatureCollection}
        id="plots-center-data"
        type="geojson"
      >
        {!outline ? (
          <Layer
            id={`${id}_symbol`}
            layout={{
              'text-field': [
                'format',
                ['string', ['get', 'name']],
                { 'font-scale': 0.7 },
              ],
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
            }}
            minzoom={minZoom}
            paint={{
              'text-color': '#fff',
              'text-halo-color': '#000',
              'text-halo-width': 1.2,
            }}
            type="symbol"
          />
        ) : null}
      </Source>
    </>
  );
}

export default PlotsLayer;
