import React from 'react';

import SelectBiomassmap from './selects/select-biomassmap';
import SelectHeatmap from './selects/select-heatmap';
import SelectPrescriptionMap from './selects/select-prescriptionmap';
import SelectSoilmap from './selects/select-soilmap';
import SelectYieldMap from './selects/select-yieldmap';
import SelectYieldPotentialMap from './selects/select-yieldpotentialmap';

import { MAP_TYPES } from '@fields/data-access';

export interface SelectMapProps {
  onChange: (value: string | null) => void;
  value: string | null;
  fieldId: number;
}

export const selectsMapping = {
  [MAP_TYPES.SOIL]: SelectSoilmap,
  [MAP_TYPES.SATELLITE]: SelectHeatmap,
  [MAP_TYPES.YIELD]: SelectYieldMap,
  [MAP_TYPES.PRESCRIPTIONS]: SelectPrescriptionMap,
  [MAP_TYPES.SAMPLES]: () => <div>Nothing available for this map type</div>,
  [MAP_TYPES.POTENTIAL]: SelectYieldPotentialMap,
  [MAP_TYPES.BIOMASS]: SelectBiomassmap,
  [MAP_TYPES.OTHER]: () => <div>Nothing available for this map type</div>,
  [MAP_TYPES.COMPARE]: () => <div>Nothing available for this map type</div>,
};

const SelectMap: React.FC<SelectMapProps & { mapType: MAP_TYPES }> = ({
  mapType,
  ...props
}) => {
  const Select = selectsMapping[mapType];

  return <Select {...props} />;
};

export default SelectMap;
