import {
  ButtonProps,
  Combobox,
  Input,
  InputBase,
  InputBaseProps,
  Loader,
  Modal,
  Popover,
  ScrollArea,
  Text,
  useCombobox,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import classes from './extended-combobox.module.css';

interface ExtendedComboboxProps {
  onOptionSubmit: (val: string) => void;
  options: {
    value: string;
    label: string;
    description: string[];
  }[];
  selectedOption: string | null;
  isPending: boolean;
  inputProps?: InputBaseProps;
}

export function ExtendedCombobox({
  options,
  isPending,
  selectedOption,
  onOptionSubmit,
  inputProps = {},
}: ExtendedComboboxProps) {
  const [opened, actions] = useDisclosure();
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const selectedOptionData = options.find(
    (item) => item.value === selectedOption,
  );

  const o = options.map(({ value, label, description }) => (
    <Combobox.Option
      active={value === selectedOption}
      className={classes.option}
      key={value}
      value={value}
    >
      <Text>{label}</Text>
      {description.map((d) => (
        <Text key={d} size="xs">
          {d}
        </Text>
      ))}
    </Combobox.Option>
  ));

  return (
    <>
      <Popover
        // disabled={selectedOptionData?.description.length === 0}
        disabled
        opened={selectedOptionData && selectedOptionData.description.length > 0}
        position="right"
        withArrow
      >
        <Popover.Target>
          <div>
            <Combobox
              onOptionSubmit={(val) => {
                onOptionSubmit(val);
                actions.close();
              }}
              // position="bottom-start"
              store={combobox}
              // width={250}
              // withArrow
              // withinPortal={false}
            >
              <Combobox.EventsTarget>
                <InputBase
                  component="button"
                  onClick={() => actions.toggle()}
                  pointer
                  rightSection={
                    isPending ? <Loader size="xs" /> : <Combobox.Chevron />
                  }
                  rightSectionPointerEvents="none"
                  type="button"
                  {...inputProps}
                >
                  {selectedOptionData?.label || (
                    <Input.Placeholder>Pick value</Input.Placeholder>
                  )}
                </InputBase>
              </Combobox.EventsTarget>
              <Modal
                centered
                classNames={{
                  content: classes.optionsModalContent,
                  body: classes.optionsModalBody,
                }}
                onClose={actions.close}
                opened={opened}
                p={0}
                withCloseButton={false}
                withinPortal
              >
                <Combobox.Options>
                  <ScrollArea.Autosize mah={450} type="scroll">
                    {o}
                  </ScrollArea.Autosize>
                </Combobox.Options>
              </Modal>
            </Combobox>
          </div>
        </Popover.Target>
        <Popover.Dropdown>
          {selectedOptionData?.description.map((d) => (
            <Text key={d} lh={0.9}>
              {d}
            </Text>
          ))}
        </Popover.Dropdown>
      </Popover>
      {/* <Modal
        centered
        onClose={actions.close}
        opened={opened}
        title="Authentication"
        withinPortal
      >
        {/* <Combobox.Options>
          <ScrollArea.Autosize mah={300} type="scroll">
            {o}
          </ScrollArea.Autosize>
        </Combobox.Options> */}
      {/* </Modal> */}
    </>
  );
}

export default ExtendedCombobox;
