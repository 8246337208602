import { saveAs } from 'file-saver';

import {
  BiomassMapsSummaryResponse,
  BiomassMapResponse,
} from '../types/biomassmap';
import {
  ExportNitrogenMapDto,
  ProcessSoilMapDto,
  ProcessYieldMapDto,
} from '../types/dto';
import { HeatmapsSummaryResponse } from '../types/heatmaps';
import {
  NitrogenExportValues,
  NitrogenMapResponse,
  NitrogenMapsSummaryResponse,
} from '../types/nitrogenmap';
import {
  PotentialMapResponse,
  PotentialMapsSummaryResponse,
} from '../types/potentialmap';
import {
  SamplesMapResponse,
  SamplesSummaryResponse,
} from '../types/samplesmap';
import {
  ProcessSoilMapResponse,
  SoilMapResponse,
  SoilMapSummaryResponse,
  UploadSoilMapResponse,
} from '../types/soilmap';
import {
  ProcessYieldMapResponse,
  UploadYieldMapResponse,
  yieldMapResponse,
  YieldMapSummaryResponse,
} from '../types/yieldmap';

import { FORM_STATUS } from '@advices/data-access';
import {
  CommonNitrogenForm,
  formatDate,
  fromBacki18n,
  handleRequest,
  MultisectActionDto,
  PkForm,
  privateClient,
  RapeseedNitrogenForm,
  ZoningFeatureCollection,
} from '@data-access';

export const fieldMapsApi = {
  getMap: async (params: { type: string; mapId: number }) => {
    const res = await handleRequest<{
      form?: {
        pk: PkForm;
        rapeseed: RapeseedNitrogenForm;
        common: CommonNitrogenForm;
      } | null;
      map: ZoningFeatureCollection;
      status: FORM_STATUS;
      unit?: string;
    }>(privateClient.get(`${params.type}/${params.mapId}/`).json());

    return res;
  },
  /**
   * Soil Maps
   */
  getSoilMapSummary: async (fieldId: number) => {
    const res = await handleRequest<SoilMapSummaryResponse>(
      privateClient
        .get(`soilmaps/`, {
          searchParams: {
            field_id: fieldId,
          },
        })
        .json(),
    );

    const options = res.map((soilMap) => ({
      label: soilMap.soil_map_type.name,
      value: soilMap.id.toString(),
    }));

    return { ...res, options };
  },
  getSoilMap: async (soilMapId: number) => {
    const res = await handleRequest<SoilMapResponse>(
      privateClient.get(`soilmaps/${soilMapId}/`).json(),
    );

    return res;
  },
  uploadSoilMap: async (args: { fieldId: number; file: Blob }) => {
    const formData = new FormData();
    formData.append('shapefile', args.file);
    formData.append('field_id', args.fieldId.toString());

    const res = await handleRequest<UploadSoilMapResponse>(
      privateClient
        .post(`soilmaps/upload/`, {
          body: formData,
        })
        .json(),
    );

    const soil_map_type = res.soil_map_type.map((s) => ({
      value: s.id.toString(),
      label: s.name,
    }));

    return { ...res, soil_map_type };
  },
  processSoilMap: async (dto: ProcessSoilMapDto) => {
    const res = await handleRequest<ProcessSoilMapResponse>(
      privateClient.post(`soilmaps/process/`, { json: dto }).json(),
    );

    return res;
  },
  deleteSoilmap: async (mapId: number) => {
    const res = await handleRequest<unknown>(
      privateClient.delete(`soilmaps/${mapId}/`).json(),
    );

    return res;
  },
  /**
   * End Soil Maps
   */

  /**
   * Heat Maps
   */
  getHeatmaps: async (args: { fieldId: number; heatmapType: string }) => {
    const res = await handleRequest<HeatmapsSummaryResponse>(
      privateClient
        .get(`plots/${args.fieldId}/heatmaps/`, {
          searchParams: {
            type: args.heatmapType,
          },
        })
        .json(),
    );

    const options = res.data
      .map((heatmap) => ({
        valueLabel: formatDate({ date: heatmap.date, format: 'display' }),
        src: heatmap.thumbnail,
        value: heatmap.id.toString(),
      }))
      .reverse();

    return {
      ...res,
      options,
    };
  },
  /**
   * End Heat Maps
   * */

  /**
   * Yield Maps
   */
  getYieldMapSummary: async (fieldId: number) => {
    const res = await handleRequest<YieldMapSummaryResponse>(
      privateClient
        .get(`yieldmaps/`, {
          searchParams: {
            field_id: fieldId,
          },
        })
        .json(),
    );

    const options = res.map((yieldMap) => ({
      label: yieldMap.name,
      value: yieldMap.id.toString(),
    }));

    return { ...res, options };
  },
  getYieldMap: async (yieldMapId: number) => {
    const res = await handleRequest<yieldMapResponse>(
      privateClient.get(`yieldmaps/${yieldMapId}/`).json(),
    );

    return res;
  },
  uploadYieldMap: async (args: { fieldId: number; file: Blob }) => {
    const formData = new FormData();
    formData.append('shapefile', args.file);
    formData.append('field_id', args.fieldId.toString());

    const res = await handleRequest<UploadYieldMapResponse>(
      privateClient
        .post(`yieldmaps/upload/`, {
          body: formData,
        })
        .json(),
    );

    const species = (lang: string) =>
      res.species.map((s) => ({
        value: s.id.toString(),
        label: fromBacki18n(s, lang),
      }));

    return { ...res, species };
  },
  processYieldMap: async (dto: ProcessYieldMapDto) => {
    const res = await handleRequest<ProcessYieldMapResponse>(
      privateClient.post(`yieldmaps/process/`, { json: dto }).json(),
    );

    return res;
  },
  deleteYieldmap: async (mapId: number) => {
    const res = await handleRequest<unknown>(
      privateClient.delete(`yieldmaps/${mapId}/`).json(),
    );

    return res;
  },
  /**
   * End Yield Maps
   */

  /**
   * Nitrogen Maps
   */
  getNitrogenMapSummary: async (fieldId: number) => {
    const res = await handleRequest<NitrogenMapsSummaryResponse>(
      privateClient
        .get(`nitrogenmaps/`, {
          searchParams: {
            field_id: fieldId,
          },
        })
        .json(),
    );

    return res;
  },
  getNitrogenMap: async (nitrogenMapId: number) => {
    const res = await handleRequest<NitrogenMapResponse>(
      privateClient.get(`nitrogenmaps/${nitrogenMapId}/`).json(),
    );

    return res;
  },
  deleteNitrogenMap: async (mapId: number) => {
    const res = await handleRequest<unknown>(
      privateClient.delete(`nitrogenmaps/${mapId}/`).json(),
    );

    return mapId;
  },
  getNitrogenExportValues: async (data: MultisectActionDto) => {
    const res = await handleRequest<NitrogenExportValues>(
      privateClient
        .post(`export/values/`, {
          json: data,
        })
        .json(),
    );

    return res;
  },
  exportNitrogenMap: async (params: ExportNitrogenMapDto) => {
    const res = await handleRequest(
      privateClient.post(`export/`, {
        json: {
          maps: params.maps,
          console: params.console,
        },
      }),
    );

    // Extract filename from header
    const filename = res.headers
      .get('content-disposition')
      ?.split(';')
      .find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim();

    const blob = await res.blob();

    saveAs(blob, filename);
  },
  getNitrogenSynthesis: async (params: {
    maps: number[];
    all: boolean;
    selectedFilters: Record<string, unknown>;
  }) => {
    const res = await handleRequest(
      privateClient.post(`nitrogenmaps/synthesis/`, {
        json: {
          maps: params.maps,
          all: params.all,
          selectedFilters: params.selectedFilters,
        },
      }),
    );

    // Extract filename from header
    const filename = res.headers
      .get('content-disposition')
      ?.split(';')
      .find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim();

    const blob = await res.blob();

    saveAs(blob, filename);
  },
  /**
   * End Nitrogen Maps
   */

  /**
   * Potential Maps
   */
  getPotentialMapSummary: async (fieldId: number) => {
    const res = await handleRequest<PotentialMapsSummaryResponse>(
      privateClient
        .get(`potentialmaps/`, {
          searchParams: {
            field_id: fieldId,
          },
        })
        .json(),
    );

    return res;
  },
  getPotentialMap: async (potentialMapId: number) => {
    const res = await handleRequest<PotentialMapResponse>(
      privateClient.get(`potentialmaps/${potentialMapId}/`).json(),
    );

    return res;
  },
  /**
   * End Potential Maps
   */

  /**
   * Biomass Maps
   */
  getBiomassMapSummary: async (fieldId: number) => {
    const res = await handleRequest<BiomassMapsSummaryResponse>(
      privateClient
        .get(`biomassmaps/`, {
          searchParams: {
            field_id: fieldId,
          },
        })
        .json(),
    );

    return res;
  },
  getBiomassMap: async (biomassMapId: number) => {
    const res = await handleRequest<BiomassMapResponse>(
      privateClient.get(`biomassmaps/${biomassMapId}/`).json(),
    );

    return res;
  },
  /**
   * End Biomass Maps
   */

  /**
   * Samples Maps
   */
  getSamplesSummary: async (fieldId: number) => {
    const res = await handleRequest<SamplesSummaryResponse>(
      privateClient
        .get(`samplingpoints/`, {
          searchParams: {
            field_id: fieldId,
          },
        })
        .json(),
    );

    const options = res.map((samplesMap) => ({
      label: samplesMap.name,
      value: samplesMap.id.toString(),
      date: samplesMap.date,
    }));

    return { res, options };
  },
  getSamplesMap: async (samplesMapId: number) => {
    const res = await handleRequest<SamplesMapResponse>(
      privateClient.get(`samplingpoints/${samplesMapId}/`).json(),
    );

    return res;
  },
  /**
   * End Samples Maps
   * */
};
