import { Box, Grid, ScrollArea } from '@mantine/core';
import { ErrorBoundary } from '@sentry/react';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { t } from 'i18next';

import {
  CropStatisticsCard,
  CropStatisticsCardError,
} from '../../components/crop-statistics-card/crop-statistics-card';
import { FarmsDataGrid } from '../../components/farms-data-grid/farms-data-grid';
import { StatisticsHeader } from '../../components/statistics-header/statistics-header';
import useAdminStats from '../../data-access/hooks/use-admin-stats/admin-stats';
import { GraphsData } from '../../data-access/types/stats';

import { ErrorPage } from './page.error';
import { PendingPage } from './page.pending';

dayjs.extend(advancedFormat);

export function StatisticsPage() {
  const stats = useAdminStats();

  const graphsData: (GraphsData & {
    crop: string;
  })[] = stats.data
    ? Object.entries(stats.data.graphs).map(
        ([key, values]: [string, GraphsData]) => {
          return {
            crop: key,
            ...values,
          };
        },
      )
    : [];

  return (
    <>
      <Box mb="x2s">
        <StatisticsHeader />
      </Box>
      <ScrollArea>
        <Grid miw={1500} my="x2s">
          {graphsData.map((data, index) => {
            const { domain, ...rest } = data.boxplot;
            return (
              <Grid.Col key={index} span={index < 2 ? 4.5 : 3}>
                <ErrorBoundary fallback={CropStatisticsCardError}>
                  <CropStatisticsCard
                    boxPlotData={
                      Object.keys(rest).map((key) => {
                        const name = key.includes('biomass')
                          ? t(`common.${key}`)
                          : dayjs(key).format('Do MMM');
                        return {
                          name,
                          ...data.boxplot[key],
                        };
                      }) as any
                    }
                    color={['#E2C949', '#CB9A3C', '#87BC25'][index]}
                    crop={data.crop}
                    domain={domain}
                    donutData={[
                      {
                        name: 'ready',
                        value: data.pieplot.field_status_number.finished,
                        color: 'green',
                      },
                      {
                        name: 'error',
                        value: data.pieplot.field_status_number.incomplete,
                        color: 'red',
                      },
                    ]}
                    totalArea={data.pieplot.total_area}
                    totalFields={data.pieplot.nbr_plots}
                  />
                </ErrorBoundary>
              </Grid.Col>
            );
          })}
        </Grid>
      </ScrollArea>
      <Box mt="x2s">
        <FarmsDataGrid />
      </Box>
    </>
  );
}

StatisticsPage.Error = ErrorPage;
StatisticsPage.Pending = PendingPage;

export default StatisticsPage;
