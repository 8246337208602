import { Position } from '@capacitor/geolocation';
import {
  Box,
  Button,
  Center,
  Drawer,
  Group,
  Paper,
  Stack,
  Text,
  VisuallyHidden,
} from '@mantine/core';
import { useDisclosure, useInputState } from '@mantine/hooks';
import { IconChevronDown, IconChevronUp } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { bbox } from '@turf/bbox';
import { booleanPointInPolygon } from '@turf/boolean-point-in-polygon';
import { point } from '@turf/helpers';
import clsx from 'clsx';
import { LngLatBoundsLike, Map, MapEvent, MapMouseEvent } from 'mapbox-gl';
import {
  ChangeEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { MapRef } from 'react-map-gl';

import FieldsInBounds, {
  CLUSTER_LAYER_ID,
  FIELDS_LAYER_ID,
  INTERACTIVE_LAYER_IDS,
} from '../../components/fields-in-bounds/fields-in-bounds';
import SelectMap from '../../components/select-map/select-map';
import SelectMapType from '../../components/select-map-type/select-map-type';

import { ErrorPage } from './page.error';
import styles from './page.module.css';

import { useQueriesWithGlobal, ZoneFeature } from '@data-access';
import {
  fieldsQueries,
  MAP_TYPES,
  MapTypeToApiRoute,
} from '@fields/data-access';
import { GeolocateControl, PfMap, StyleControl, ZoningLayer } from '@map';
import { FullMainHeight } from '@ui';

export function FieldsMap() {
  const { t } = useTranslation();
  const search = useSearch({
    from: '/_app/map',
  });
  const navigate = useNavigate({
    from: '/map',
  });
  const mapRef = useRef<MapRef>(null);
  const [opened, actions] = useDisclosure();
  const [drawerState, setDrawerState] = useState<0 | 1 | 2>(1);
  const [selectedField, setSelectedField] = useState<number | null>(null);
  const [mapType, setMapType] = useInputState<string | null>(null);
  const [mapId, setMapId] = useInputState<string | null>(null);
  const [currentFeature, setCurrentFeature] = useState<ZoneFeature | null>(
    null,
  );
  const [currentPosition, setCurrentPosition] = useState<Position | null>(null);

  const queries = useQueriesWithGlobal(fieldsQueries);
  const field = useQuery({
    ...queries.detail(selectedField!),
    enabled: selectedField !== null,
  });
  const maxBounds = useQuery(queries.maxBounds());
  const map = useQuery({
    ...queries.mapDetail({
      type: MapTypeToApiRoute[mapType as MAP_TYPES],
      mapId: Number(mapId!),
    }),
    enabled: mapId !== null && mapType !== null,
  });

  const handleMapClick = (e: MapMouseEvent) => {
    const feature = e.features?.[0];

    if (!feature) {
      return;
    }

    switch (feature.layer?.id) {
      case FIELDS_LAYER_ID: {
        if (feature.id) {
          if (selectedField !== feature.id) {
            setSelectedField(feature.id as number);
            setMapType(null);
            setMapId(null);
          }
          actions.open();
        }
        break;
      }
      case CLUSTER_LAYER_ID:
      case `${CLUSTER_LAYER_ID}_points`: {
        const [x1, y1, x2, y2] =
          JSON.parse(feature.properties?.bbox) || bbox(feature.geometry);
        e.target.fitBounds([x1, y1, x2, y2], {
          padding: 100,
          animate: false,
        });
        break;
      }
    }
  };

  const handlePositionChange = (position: Position) => {
    setCurrentPosition(position);
  };

  const handleDrawerStateChange = () => {
    // toggle between 0, 1, 2
    setDrawerState((state) => ((state + 1) % 3) as 0 | 1 | 2);
  };

  const handleChangeMapType: ChangeEventHandler<HTMLSelectElement> = (e) => {
    const value = e.currentTarget.value as MAP_TYPES;
    setMapType(value);
    setMapId(null);

    if (value === MAP_TYPES.SATELLITE) {
      setDrawerState(2);
    }
  };

  useEffect(() => {
    if (map.data?.map && currentPosition) {
      // find on which zone of the map the user is
      const feature = map.data.map.features.find((f) => {
        const pt = point([
          currentPosition.coords.longitude,
          currentPosition.coords.latitude,
        ]);
        return booleanPointInPolygon(pt, f.geometry);
      });
      setCurrentFeature(feature ?? null);
    }
  }, [map.data, currentPosition]);

  useEffect(() => {
    // on field change set mapType to first available map
    if (field.data?.properties.maps) {
      setMapType(
        field.data.properties.maps.find((m) => m.numbers && m.numbers > 0)
          ?.type ?? MAP_TYPES.SATELLITE,
      );
    }
  }, [field.data?.properties.maps]);

  const zoomOnBbox = useCallback(
    (m: Map) => {
      if (search?.bbox) {
        m.fitBounds(search.bbox as LngLatBoundsLike, {
          padding: 100,
          animate: false,
        });
        navigate({
          to: '/map',
          search: {},
        });
      }
    },
    [navigate, search],
  );

  const fitMaxBounds = useCallback(
    (m: Map) => {
      if (maxBounds.data?.bbox && m) {
        m.fitBounds(maxBounds.data.bbox, {
          padding: 20,
        });
      }
    },
    [maxBounds.data],
  );

  const handleLoad = (e: MapEvent) => {
    const zoom = e.target.getZoom();
    if (search.bbox) {
      zoomOnBbox(e.target);
    } else if (zoom === 6) {
      fitMaxBounds(e.target);
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      const zoom = mapRef.current.getMap().getZoom();
      if (search.bbox) {
        zoomOnBbox(mapRef.current.getMap());
      } else if (zoom === 6) {
        fitMaxBounds(mapRef.current.getMap());
      }
    }
  }, [fitMaxBounds, search, zoomOnBbox]);

  return (
    <FullMainHeight.Root>
      <FullMainHeight.Content>
        {currentFeature ? (
          <Paper
            pos="absolute"
            radius="lg"
            style={{
              zIndex: 100,
              top: 10,
              left: 10,
              right: 10,
              backgroundColor: currentFeature?.properties.color,
            }}
          >
            <Center>
              <Text fw="bold" fz={32}>
                {typeof currentFeature?.properties.value === 'number'
                  ? currentFeature.properties.value.toFixed(2)
                  : currentFeature?.properties.value}
                {map.data?.unit && ` - ${map.data.unit}`}
              </Text>
            </Center>
          </Paper>
        ) : null}
        <Paper
          className={clsx(styles['map-container'], 'border-light', {
            [styles[`map-container-overlayed-${drawerState}`]]: opened,
          })}
          p={0}
          pos="relative"
          shadow="sm"
        >
          <PfMap
            interactiveLayerIds={INTERACTIVE_LAYER_IDS}
            onClick={handleMapClick}
            onLoad={handleLoad}
            ref={mapRef}
          >
            <StyleControl />
            <GeolocateControl
              onPositionChange={handlePositionChange}
              position="bottom-right"
            />

            <FieldsInBounds withSatelliteControl={false} />
            {map.data ? (
              <ZoningLayer data={map.data.map} filled outline={false} />
            ) : null}

            <Drawer
              classNames={{
                content: styles.drawerContent,
                header: styles.p0,
                body: styles.p0,
              }}
              keepMounted
              onClose={() => setSelectedField(null)}
              opened={opened}
              position="bottom"
              radius="lg"
              size={drawerState === 1 ? 300 : drawerState === 2 ? '60%' : 100}
              withCloseButton={false}
              withOverlay={false}
            >
              <Group>
                <Button
                  color="dark"
                  fullWidth
                  onClick={handleDrawerStateChange}
                  size="compact-sm"
                  variant="transparent"
                >
                  <Center
                    bg="dark"
                    style={{
                      height: 15,
                      width: 50,
                      borderRadius: 'var(--mantine-radius-xs)',
                    }}
                  >
                    {drawerState === 2 ? (
                      <IconChevronDown color="white" opacity={0.6} />
                    ) : (
                      <IconChevronUp color="white" opacity={0.6} />
                    )}
                  </Center>
                  <VisuallyHidden>Toggle drawer</VisuallyHidden>
                </Button>
              </Group>
              <Stack>
                <Box>
                  <Text fw="bold" lh={1} size="lg">
                    {field.data?.properties.name}
                  </Text>
                  <Text lh={1} size="sm">
                    {field.data?.properties.species?.name}
                  </Text>
                  <Text lh={1} size="sm">
                    {field.data?.properties.area.toFixed(2)} ha
                  </Text>
                </Box>
                <SelectMapType
                  maps={field.data?.properties.maps}
                  onChange={handleChangeMapType}
                  value={mapType ?? ''}
                />
                {selectedField !== null && mapType !== null ? (
                  <SelectMap
                    fieldId={selectedField}
                    mapType={mapType as MAP_TYPES}
                    onChange={setMapId}
                    value={mapId}
                  />
                ) : null}

                <Button
                  color="dark"
                  onClick={actions.close}
                  size="compact-md"
                  variant="light"
                >
                  {t('common.close')}
                </Button>
              </Stack>
            </Drawer>
          </PfMap>
        </Paper>
      </FullMainHeight.Content>
    </FullMainHeight.Root>
  );
}

FieldsMap.Error = ErrorPage;

export default FieldsMap;
