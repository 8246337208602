import clsx from 'clsx';

import classes from './full-main-height.module.css';

interface FullMainHeightContentProps {
  children: React.ReactNode;
  className?: string;
}

export const FullMainHeightContent = ({
  children,
  className,
}: FullMainHeightContentProps) => {
  return (
    <div className={clsx(classes.contentWrapper, className)}>
      <div className={classes.content}>{children}</div>
    </div>
  );
};
