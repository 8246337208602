import {
  Card,
  Group,
  Image,
  Loader,
  Pagination,
  ScrollArea,
  Skeleton,
  Stack,
  Text,
  TextInput,
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { IconSearch } from '@tabler/icons-react';
import { useQueryClient } from '@tanstack/react-query';
import { RouteComponent, useNavigate } from '@tanstack/react-router';
import { bbox } from '@turf/bbox';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import usePrescriptions from '../../hooks/prescriptions/use-prescriptions/prescriptions';

import classes from './page.module.css';

import {
  useColFilters,
  usePagination,
  useQueriesWithGlobal,
} from '@data-access';
import { prescriptionsQueries } from '@fields/data-access';
import { FullMainHeight } from '@ui';

export const PrescriptionsListPage: RouteComponent = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate({
    from: '/prescriptions',
  });
  const viewport = useRef<HTMLDivElement>(null);
  const [filters, setFilters] = useColFilters('prescriptions');
  const [, setPagination] = usePagination('prescriptions');
  const [value, setValue] = useState('');
  const [debounced] = useDebouncedValue(value, 200);

  const queries = useQueriesWithGlobal(prescriptionsQueries);
  const prescriptions = usePrescriptions();

  const pages = prescriptions.data?.count
    ? Math.ceil(prescriptions.data?.count / 25)
    : 0;

  const handleClick = async (id: number) => {
    try {
      const { map } = await queryClient.fetchQuery(queries.detail(id));
      const [x1, y1, x2, y2] = bbox(map);
      navigate({
        to: '/map',
        search: {
          bbox: [x1, y1, x2, y2],
        },
      });
    } catch (error) {
      // something went wrong
    }
  };

  const handleChangePage = (page: number) => {
    setPagination({ pageIndex: page - 1, pageSize: 25 });
    viewport.current!.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    setPagination({ pageIndex: 0, pageSize: 25 });
    if (debounced) {
      setFilters([{ id: 'field__name', value: debounced }]);
    } else {
      setFilters([]);
    }
  }, [debounced]);

  return (
    <FullMainHeight.Root>
      <FullMainHeight.Content className={classes.content}>
        <ScrollArea h="100%" viewportRef={viewport}>
          <Stack p="md">
            <TextInput
              leftSection={<IconSearch />}
              onChange={(event) => setValue(event.currentTarget.value)}
              placeholder={t('prescriptions.search_placeholder')}
              rightSection={
                prescriptions.isRefetching ? <Loader size="sm" /> : null
              }
              value={value}
            />
            {prescriptions.isLoading ? (
              <>
                <Skeleton animate height={83} w="100%" />
                <Skeleton animate height={83} w="100%" />
                <Skeleton animate height={83} w="100%" />
              </>
            ) : null}
            {prescriptions.data?.results.map((prescription) => (
              <Card
                component="button"
                onClick={() => handleClick(prescription.id)}
                radius="sm"
                shadow="none"
                withBorder
              >
                <Group justify="space-between" w="100%" wrap="nowrap">
                  <Stack gap={0} ta="left">
                    <Text fw="bold">{prescription.field.name}</Text>
                    <Text>{prescription.product.name}</Text>
                  </Stack>

                  <object
                    data={prescription.field.thumbnail}
                    height="40"
                    type="image/svg+xml"
                    width="40"
                  >
                    <Image
                      alt={prescription.field.name}
                      h={40}
                      src={prescription.field.thumbnail}
                      w={40}
                    />
                  </object>
                </Group>
              </Card>
            ))}
          </Stack>
          {pages > 1 ? (
            <Group pb="md" px="md">
              <Pagination
                className={classes.pagination}
                onChange={handleChangePage}
                size="lg"
                total={pages}
                withControls={false}
              />
            </Group>
          ) : null}
        </ScrollArea>
      </FullMainHeight.Content>
    </FullMainHeight.Root>
  );
};

export default PrescriptionsListPage;
