import { Select, Stack } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { featureCollection } from '@turf/helpers';
import React, { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectMapProps } from '../select-map';

import { FORM_STATUS } from '@advices/data-access';
import { layersOptions } from '@data-access';
import { useFieldsQueries } from '@fields/data-access';
import { ImageLayer, PlotsLayer, SelectImageSlider } from '@map';

const SelectHeatmap: React.FC<SelectMapProps> = ({
  fieldId,
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  const queries = useFieldsQueries();
  const [heatmapType, setHeatmapType] = useState('NDVI');
  const [selectedHeatmap, setSelectedHeatmap] = useState<string | null>(null);

  const field = useQuery(queries.detail(fieldId));
  const maps = useQuery({
    ...queries.heatmapsSummary({
      fieldId: Number(fieldId),
      heatmapType: heatmapType,
    }),
    refetchInterval(query) {
      return query.state.data?.status !== FORM_STATUS.FINISHED ? 5000 : false;
    },
  });

  const selectedMap = maps.data?.data.find(
    (map) => map.id.toString() === selectedHeatmap,
  );

  useEffect(() => {
    if (maps.data?.options?.length && !selectedHeatmap) {
      const lastMap = maps.data.options[0];
      setSelectedHeatmap(lastMap.value);
    }
  }, [maps.data, selectedHeatmap]);

  return (
    <>
      <Stack>
        <Select
          data={layersOptions(t)}
          disabled={maps.data?.status !== FORM_STATUS.FINISHED}
          onChange={(v) => setHeatmapType(v ?? 'LAI')}
          value={heatmapType}
        />
        <SelectImageSlider
          key={heatmapType + maps.data?.options?.length}
          onChange={setSelectedHeatmap}
          options={maps.data?.options || []}
          value={selectedHeatmap || ''}
        />
      </Stack>
      {selectedMap &&
      maps.data?.status === FORM_STATUS.FINISHED &&
      field.data ? (
        <Fragment>
          <ImageLayer
            beforeId="fields-outline-heatmap_outline"
            coordinates={maps.data?.corners_coords}
            imageUrl={selectedMap.url}
          />
          <PlotsLayer
            data={featureCollection([field.data])}
            id="fields-outline-heatmap"
            minZoom={12}
            outline
          />
        </Fragment>
      ) : null}
    </>
  );
};

export default SelectHeatmap;
