import { Grid, Skeleton } from '@mantine/core';

export const PendingPage = () => {
  return (
    <Grid>
      <Grid.Col order={{ base: 2, lg: 3 }} span={8}>
        <Skeleton height={382} />
        <Skeleton height={382} mt="md" w={332} />
      </Grid.Col>
      <Grid.Col order={4} span={4}>
        <Skeleton height={382} />
      </Grid.Col>
    </Grid>
  );
};

export default PendingPage;
