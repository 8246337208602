import { Select } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ExtendedCombobox from '../../extended-combobox/extended-combobox';
import { SelectMapProps } from '../select-map';

import { useFieldsQueries } from '@fields/data-access';

const SelectYieldPotentialMap: React.FC<SelectMapProps> = ({
  fieldId,
  onChange,
  value,
}) => {
  const { t } = useTranslation();
  const queries = useFieldsQueries();
  const summary = useQuery(queries.prescriptionsSummary(fieldId));

  const options =
    summary.data?.map((item) => ({
      value: item.id.toString(),
      label: item.name,
      description: [
        `${t('common.created_date_prefix')} ${dayjs(item.created_at).format(
          `DD/MM/YYYY [${t('common.time_prefix')}] H[h]mm`,
        )}`,
      ],
    })) ?? [];

  useEffect(() => {
    if (summary.data?.length && !value) {
      onChange(summary.data[0].id.toString());
    }
  }, [summary.data, onChange, value]);

  return (
    <ExtendedCombobox
      isPending={summary.isPending}
      onOptionSubmit={onChange}
      options={options}
      selectedOption={value}
    />
  );
};

export default SelectYieldPotentialMap;
