import {
  ActionIcon,
  Box,
  Portal,
  Progress,
  Select,
  Stack,
  Switch,
} from '@mantine/core';
import { DatePickerInput } from '@mantine/dates';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMap } from 'react-map-gl';

import classes from './satellite-imagery-control.module.css';

import { layersOptions } from '@data-access';

type SwitchOnChange = React.ComponentProps<typeof Switch>['onChange'];

interface SatelliteImageryControlProps {
  dates: string[];
  loading?: boolean;
  onEnableChange?: (enabled: boolean) => void;
  date: Date | null;
  onDateChange: (date: Date) => void;
  imageType: string;
  onImageTypeChange: (imageType: string) => void;
}

export function SatelliteImageryControl({
  dates,
  date,
  onEnableChange,
  onDateChange,
  imageType,
  onImageTypeChange,
  loading,
}: SatelliteImageryControlProps) {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const map = useMap();
  const mapId = map.current?.getMap().getContainer().id;

  const handleChange: SwitchOnChange = (event) => {
    if (onEnableChange) {
      onEnableChange(event.target.checked);
    }
    setChecked(event.target.checked);
  };

  const handleSelectChange = (value: string | null) => {
    if (onImageTypeChange && value) {
      onImageTypeChange(value);
    }
  };

  const handlePreviousNext = (next: boolean) => {
    if (date === null && dates.length > 0) {
      return onDateChange(dayjs(dates[dates.length - 1]).toDate());
    }

    const index = dates.indexOf(dayjs(date).format('YYYY-MM-DD'));
    const newIndex = next ? index + 1 : index - 1;

    if (newIndex < 0) {
      // loop
      return onDateChange(dayjs(dates[dates.length - 1]).toDate());
    } else if (newIndex >= dates.length) {
      // loop
      return onDateChange(dayjs(dates[0]).toDate());
    }
    return onDateChange(dayjs(dates[newIndex]).toDate());
  };

  if (!mapId) {
    return null;
  }

  return (
    <Portal
      target={
        document &&
        (document
          .getElementById(mapId)
          ?.getElementsByClassName('mapboxgl-ctrl-top-left')[0] as HTMLElement)
      }
    >
      <Stack
        className={clsx(classes.container, 'mapboxgl-ctrl', {
          [classes.blockPadding]: checked,
        })}
      >
        {loading ? (
          <Progress
            animated
            className={classes.loadingBar}
            color="pf-green"
            radius={0}
            value={100}
          />
        ) : null}
        <Switch
          checked={checked}
          label={t('map.satellite-imagery-control.label')}
          labelPosition="right"
          onChange={handleChange}
        ></Switch>
        {checked ? (
          <Box>
            <Select
              data={layersOptions(t)}
              defaultValue="NDVI"
              onChange={handleSelectChange}
              value={imageType}
            />
            <DatePickerInput
              classNames={{
                input: classes.datePickerInput,
              }}
              excludeDate={(d) =>
                !dates.includes(dayjs(d).format('YYYY-MM-DD'))
              }
              getMonthControlProps={(month) => ({
                // disable months without data
                disabled: !dates.some((d) =>
                  d.startsWith(dayjs(month).format('YYYY-MM')),
                ),
              })}
              getYearControlProps={(year) => ({
                // disable years without data
                disabled: !dates.some((d) =>
                  d.startsWith(dayjs(year).format('YYYY')),
                ),
              })}
              leftSection={
                <ActionIcon
                  className={classes.sectionsActions}
                  color="pf-green"
                  onClick={() => handlePreviousNext(false)}
                >
                  <IconChevronLeft color="white" />
                </ActionIcon>
              }
              mt="md"
              onChange={(d) => {
                if (d) {
                  onDateChange(d);
                }
              }}
              rightSection={
                <ActionIcon
                  className={classes.sectionsActions}
                  onClick={() => handlePreviousNext(true)}
                >
                  <IconChevronRight color="white" />
                </ActionIcon>
              }
              value={date}
              valueFormat="DD/MM/YYYY"
            />
          </Box>
        ) : null}
      </Stack>
    </Portal>
  );
}

export default SatelliteImageryControl;
