import { saveAs } from 'file-saver';

import {
  CreatePrescriptionDto,
  CreatePrescriptionResponse,
  ExportPrescriptionsDto,
  GetPrescriptionCreateValuesDto,
  ListValuesResponse,
  PrescriptionCreateValues,
  PrescriptionResponse,
  PrescriptionsExportValues,
  PrescriptionsListResponse,
  PrescriptionsSummaryResponse,
} from '../types/prescriptions';

import {
  Filters,
  formatFilters,
  fromBacki18n,
  handleRequest,
  MultisectActionDto,
  privateClient,
} from '@data-access';

export const prescriptionsApi = {
  getAll: async (filters: Filters) => {
    const res = await handleRequest<PrescriptionsListResponse>(
      privateClient
        .get('prescriptionmaps/', {
          searchParams: {
            ...formatFilters(filters),
          },
        })
        .json(),
    );

    return res;
  },

  getFieldSummary: async (fieldId: number) => {
    const res = await handleRequest<PrescriptionsSummaryResponse>(
      privateClient
        .get(`nitrogenmaps/`, {
          searchParams: {
            field_id: fieldId,
          },
        })
        .json(),
    );

    return res;
  },
  getOne: async (nitrogenMapId: number) => {
    const res = await handleRequest<PrescriptionResponse>(
      privateClient.get(`nitrogenmaps/${nitrogenMapId}/`).json(),
    );

    return res;
  },
  createOne: async (args: CreatePrescriptionDto) => {
    const res = await handleRequest<CreatePrescriptionResponse>(
      privateClient
        .post(`plots/${args.field}/compute_prescription/`, {
          json: args,
        })
        .json(),
    );

    return res;
  },
  deleteOne: async (mapId: number) => {
    const res = await handleRequest<unknown>(
      privateClient.delete(`nitrogenmaps/${mapId}/`).json(),
    );

    return res;
  },
  deleteMany: async (data: MultisectActionDto) => {
    const res = await handleRequest<unknown>(
      privateClient.delete(`prescriptionmaps/bulk_delete/`, {
        json: data,
      }),
    );

    return res;
  },
  getListValues: async () => {
    const res = await handleRequest<ListValuesResponse>(
      privateClient.get(`prescriptionmaps/values/`).json(),
    );

    return res;
  },
  getCreateValues: async (args: GetPrescriptionCreateValuesDto) => {
    const fromField = args.type === 'heatmaps' || args.type === 'plots';
    const type = fromField ? 'plots' : args.type;
    const id = fromField ? args.fieldId : args.id;
    const res = await handleRequest<PrescriptionCreateValues>(
      privateClient.get(`${type}/${id}/compute_prescription/`).json(),
    );

    const product_group = (lang: string) =>
      res.product_group.map((p) => ({
        value: p.id.toString(),
        label: fromBacki18n(p, lang),
      }));

    const products = (lang: string) =>
      res.product.map((p) => ({
        parent: p.group.toString(),
        value: p.id.toString(),
        label: fromBacki18n(p, lang),
      }));

    return {
      ...res,
      options: {
        product_group,
        products,
      },
    };
  },
  getExportValues: async (maps: number[]) => {
    const res = await handleRequest<PrescriptionsExportValues>(
      privateClient
        .post(`export/values/`, {
          json: {
            maps,
          },
        })
        .json(),
    );

    return res;
  },
  export: async (data: MultisectActionDto & { console: number }) => {
    const res = await handleRequest(
      privateClient.post(`export/`, {
        json: data,
      }),
    );

    // Extract filename from header
    const filename = res.headers
      .get('content-disposition')
      ?.split(';')
      .find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim();

    const blob = await res.blob();

    saveAs(blob, filename);
  },
  getSynthesis: async (params: MultisectActionDto) => {
    const res = await handleRequest(
      privateClient.post(`prescriptionmaps/synthesis/`, {
        json: params,
      }),
    );

    // Extract filename from header
    const filename = res.headers
      .get('content-disposition')
      ?.split(';')
      .find((n) => n.includes('filename='))
      ?.replace('filename=', '')
      .trim();

    const blob = await res.blob();

    saveAs(blob, filename);
  },
};
