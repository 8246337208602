import { queryOptions } from '@tanstack/react-query';

import { connectivityApi } from '../apis/connectivity';

import { FORM_STATUS } from '@advices/data-access';
import { Globals, useQueriesWithGlobal } from '@data-access';

export const connectivityQueries = (globals: Globals) => ({
  all: () =>
    queryOptions({
      queryKey: [globals, 'connectivity'],
      queryFn: () => connectivityApi.getConnectivity(),
    }),
  consoles: () =>
    queryOptions({
      queryKey: [...connectivityQueries(globals).all().queryKey, 'consoles'],
      queryFn: () => connectivityApi.getConsoles(),
    }),
  farms: () =>
    queryOptions({
      queryKey: [...connectivityQueries(globals).all().queryKey, 'farms'],
      queryFn: () => connectivityApi.getFarms(),
    }),
  importStatus: (id?: number) =>
    queryOptions({
      queryKey: [
        ...connectivityQueries(globals).all().queryKey,
        'import-status',
        id,
      ],
      queryFn: () => connectivityApi.getImportStatus(id!),
      enabled: !!id,
      refetchInterval(query) {
        return query.state.data?.status !== FORM_STATUS.FINISHED ? 5000 : false;
      },
    }),
  ssoUrl: (params: { connectivity_link: number }) =>
    queryOptions({
      queryKey: [
        ...connectivityQueries(globals).all().queryKey,
        'sso-url',
        params,
      ],
      queryFn: () => connectivityApi.getSsoUrl(params),
    }),
});

export const useConnectivityQueries = () =>
  useQueriesWithGlobal(connectivityQueries);
