import { Button, Group, NativeSelect, Select } from '@mantine/core';
import { isNotEmpty, useForm } from '@mantine/form';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useConnectivityQueries } from '../../data-access/queries/connectivity';

import { Drawer } from '@ui';

interface AddConsoleFormValues {
  manufacturer: string;
  console_id: string | null;
}

export interface TransformedValues {
  manufacturer: string;
  console_id: number;
}

type TransformValues = (values: AddConsoleFormValues) => TransformedValues;

interface AddConsoleFormProps {
  opened: boolean;
  onClose: () => void;
  onSubmit: (values: TransformedValues) => void;
  loading: boolean;
}

export function AddConsoleForm({
  opened,
  onClose,
  onSubmit,
  loading,
}: AddConsoleFormProps) {
  const { t } = useTranslation();
  const form = useForm<AddConsoleFormValues, TransformValues>({
    initialValues: {
      manufacturer: '',
      console_id: null,
    },
    validate: {
      manufacturer: isNotEmpty(t('form.required')),
      console_id: isNotEmpty(t('form.required')),
    },
    transformValues: (values) => ({
      ...values,
      console_id: parseInt(values.console_id!),
    }),
    onValuesChange(values, previous) {
      if (previous.manufacturer !== values.manufacturer) {
        form.setFieldValue('console_id', null);
      }
    },
  });

  const connectivityQueries = useConnectivityQueries();
  const consoles = useQuery(connectivityQueries.consoles());

  const handleClose = () => {
    form.reset();
    onClose();
  };

  useEffect(() => {
    if (!opened) {
      form.reset();
    }
  }, [opened]);

  return (
    <Drawer
      onClose={handleClose}
      opened={opened}
      position="right"
      size="auto"
      title={t('connectivity.add-console-title')}
    >
      <form onSubmit={form.onSubmit(onSubmit)}>
        <NativeSelect
          data={consoles.data?.manufacturer}
          label={t('connectivity.manufacturer-label')}
          required
          {...form.getInputProps('manufacturer')}
        />
        <NativeSelect
          data={consoles.data?.options[form.values.manufacturer]}
          label={t('connectivity.console-label')}
          mt="sm"
          required
          {...form.getInputProps('console_id')}
        />

        <Group mt="xl">
          <Button loading={loading} type="submit">
            {t('common.save')}
          </Button>
          <Button color="red" onClick={handleClose} type="button">
            {t('common.cancel')}
          </Button>
        </Group>
      </form>
    </Drawer>
  );
}

export default AddConsoleForm;
