import { Geolocation, Position } from '@capacitor/geolocation';
import { Motion } from '@capacitor/motion';
import clsx from 'clsx';
import { Map, Marker } from 'mapbox-gl';

import classes from './styles.module.css';

enum MapboxGeolocateState {
  Waiting = 'waiting',
  Active = 'active',
  Disabled = 'disabled',
}

// mapboxgl-ctrl-geolocate mapboxgl-ctrl-geolocate-waiting mapboxgl-ctrl-geolocate-active
export class MapboxGeolocateControl {
  _map: Map | undefined;
  _container: HTMLElement | undefined;
  _marker: Marker | undefined;
  button: HTMLButtonElement | undefined;
  spanIcon: HTMLSpanElement | undefined;

  _heading = 0;

  isGeolocating = false;
  isFollowing = false;
  watchId: string | undefined;

  onPositionChange: ((position: Position) => void) | undefined;

  constructor(options: { onPositionChange?: (position: Position) => void }) {
    this.onPositionChange = options.onPositionChange;
  }

  onTouchEnd() {
    // stop following user location on user interaction
    this.isFollowing = false;
    this.setButtonState(MapboxGeolocateState.Disabled);
  }

  onAdd(map: Map) {
    this._map = map;
    this._map.on('touchend', this.onTouchEnd.bind(this));
    // control container
    this._container = document.createElement('div');

    // control button
    this.button = document.createElement('button');
    this.button.className = 'mapboxgl-ctrl-geolocate';
    this.button.ariaLabel = 'Find my location';
    // this.startGeolocate();
    this.button.addEventListener('click', this.startGeolocate.bind(this));

    // control icon
    this.spanIcon = document.createElement('span');
    this.spanIcon.className = 'mapboxgl-ctrl-icon';
    this.spanIcon.title = 'Find my location';
    this.spanIcon.ariaHidden = 'true';

    // add to dom
    this.button.appendChild(this.spanIcon);
    this._container.appendChild(this.button);
    this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';
    return this._container;
  }

  onRemove() {
    this._container?.parentNode?.removeChild(this._container);
    this.button?.removeEventListener('click', this.startGeolocate.bind(this));
    this._map?.off('idle', this.onTouchEnd.bind(this));

    this._map = undefined;
  }

  setButtonState(state: MapboxGeolocateState) {
    if (this.button) {
      switch (state) {
        case MapboxGeolocateState.Waiting:
          this.button.className = `mapboxgl-ctrl-geolocate mapboxgl-ctrl-geolocate-waiting`;
          break;
        case MapboxGeolocateState.Active:
          this.button.className = `mapboxgl-ctrl-geolocate mapboxgl-ctrl-geolocate-active`;
          break;
        case MapboxGeolocateState.Disabled:
          this.button.className = `mapboxgl-ctrl-geolocate`;
          break;
        default:
          break;
      }
    }
  }

  startGeolocate() {
    if (this.isFollowing) {
      this.setButtonState(MapboxGeolocateState.Disabled);
      this.isFollowing = false;
    } else {
      this.setButtonState(MapboxGeolocateState.Waiting);
      this.geolocate();
    }
  }

  async geolocate() {
    let coordinates: Position | undefined;

    try {
      coordinates = await Geolocation.getCurrentPosition();
      this.setButtonState(MapboxGeolocateState.Active);
      this.isGeolocating = true;
      this.isFollowing = true;
    } catch (error) {
      this.setButtonState(MapboxGeolocateState.Disabled);
      return;
    }

    Motion.addListener('orientation', (e) => {
      const trueHeading = (360 - (e.alpha || 0)) % 360;
      this._heading = trueHeading;
      this.updateMarkerRotation();
    });

    if (this._marker) {
      this._marker.remove();
    }

    const uel = document.createElement('div');
    uel.id = 'mapboxgl-user-location';
    uel.className = clsx(
      classes.locationDot,
      'mapboxgl-user-location mapboxgl-marker mapboxgl-marker-anchor-center mapboxgl-user-location-show-heading',
    );
    const locationDot = document.createElement('div');
    locationDot.classList.add('mapboxgl-user-location-dot');
    uel.appendChild(locationDot);

    const locationHeading = document.createElement('div');
    locationHeading.classList.add('mapboxgl-user-location-heading');
    uel.appendChild(locationHeading);

    if (this._map) {
      this._marker = new Marker({
        element: uel,
        rotationAlignment: 'map',
        pitchAlignment: 'map',
      })
        .setLngLat([coordinates.coords.longitude, coordinates.coords.latitude])
        .addTo(this._map);

      if (this.isFollowing) {
        // fly to user location
        this._map.flyTo({
          center: [coordinates.coords.longitude, coordinates.coords.latitude],
          zoom: this._map.getZoom(),
          bearing: this._heading,
          animate: false,
        });
      }

      try {
        this.watchId = await Geolocation.watchPosition(
          {
            enableHighAccuracy: true,
            timeout: 1500,
            minimumUpdateInterval: 500,
          },
          (position, err) => {
            if (position) {
              this._marker?.setLngLat([
                position.coords.longitude,
                position.coords.latitude,
              ]);
              if (this.isFollowing) {
                this._map?.flyTo({
                  center: [position.coords.longitude, position.coords.latitude],
                  zoom: this._map.getZoom(),
                  bearing: this._heading,
                  animate: true,
                  curve: 1,
                });
              }
              if (this.onPositionChange) {
                this.onPositionChange(position);
              }
            }
          },
        );
      } catch (e) {
        console.error(e);
      }
    }
  }

  updateMarkerRotation() {
    if (this._marker) {
      this._marker.setRotation(this._heading);
    }
  }

  async clearWatch() {
    if (this.watchId) {
      await Geolocation.clearWatch({ id: this.watchId });
      this.watchId = undefined;
    }
  }
}

export default MapboxGeolocateControl;
