import { Paper } from '@mantine/core';
import { ErrorRouteComponent } from '@tanstack/react-router';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './page.module.css';

import { CommonError, FullMainHeight } from '@ui';

export const ErrorPage: ErrorRouteComponent = ({ error, reset }) => {
  const { t } = useTranslation();
  return (
    <FullMainHeight.Root>
      <FullMainHeight.Content>
        <Paper
          className={clsx(styles['map-container'], 'border-light')}
          p={0}
          shadow="sm"
        >
          <CommonError
            description={t('common.error.pageError')}
            error={error}
            reset={reset}
          />
        </Paper>
      </FullMainHeight.Content>
    </FullMainHeight.Root>
  );
};

export default ErrorPage;
