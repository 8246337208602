import { Group, Paper, Stack, Title } from '@mantine/core';
import { ErrorRouteComponent } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';

import { CommonError } from '@ui';

export const ErrorPage: ErrorRouteComponent = ({ reset, error }) => {
  const { t } = useTranslation();

  return (
    <Stack>
      <Paper>
        <Group justify="space-between">
          <Title order={2}>{t('connectivity.consoles-title')}</Title>
        </Group>
      </Paper>
      <CommonError
        description={t('common.error.pageError')}
        error={error}
        reset={reset}
      />
    </Stack>
  );
};

export default ErrorPage;
