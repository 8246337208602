import { bbox } from '@turf/bbox';
import { centroid } from '@turf/centroid';
import { FeatureCollection, Point } from 'geojson';
import { Layer, Source } from 'react-map-gl';

/* eslint-disable-next-line */
export interface ClusterLayerProps {
  data: GeoJSON.FeatureCollection<GeoJSON.Point>;
  clustersMaxZoom?: number;
  pointsMaxZoom?: number;
  layerId?: string;
  clusterColor?: string;
}

export function ClusterLayer(props: ClusterLayerProps) {
  const getCentroids = (
    featureCollection: FeatureCollection,
  ): FeatureCollection<Point> => {
    const centroids = featureCollection.features.map((feature) =>
      centroid(feature, {
        properties: {
          ...feature.properties,
          bbox: bbox(feature),
        },
      }),
    );
    return {
      type: 'FeatureCollection',
      features: centroids,
    };
  };

  const centroids = getCentroids(props.data);

  return (
    <>
      <Source
        data={props.data}
        id="cluster-data"
        type="geojson"
        // cluster
      >
        <Layer
          filter={['has', 'count']}
          id={props.layerId}
          maxzoom={props.clustersMaxZoom || 6}
          paint={{
            'circle-color': ['coalesce', ['get', 'color'], props.clusterColor],
            'circle-opacity': 1,
            'circle-radius': [
              'min',
              ['+', 10, ['number', ['get', 'count']]],
              25,
            ],
          }}
          type="circle"
        />
      </Source>
      <Source data={centroids} id="centroid-source" type="geojson">
        <Layer
          filter={['!', ['has', 'count']]}
          id={`${props.layerId}_points`}
          maxzoom={props.pointsMaxZoom || 9}
          paint={{
            'circle-color': ['coalesce', ['get', 'color'], props.clusterColor],
            'circle-radius': 8,
          }}
          type="circle"
        />
      </Source>
    </>
  );
}

export default ClusterLayer;
