import { Container } from '@mantine/core';
import { Outlet } from '@tanstack/react-router';

import styles from './auth-layout.module.css';

export function AuthLayout() {
  return (
    <div className={styles['container']}>
      <Container className={styles.formContainer} size="xs">
        <Outlet />
      </Container>
    </div>
  );
}

export default AuthLayout;
