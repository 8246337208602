import { Select } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import ExtendedCombobox from '../../extended-combobox/extended-combobox';
import { SelectMapProps } from '../select-map';

import { useFieldsQueries } from '@fields/data-access';

const SelectYieldMap: React.FC<SelectMapProps> = ({
  fieldId,
  onChange,
  value,
}) => {
  const queries = useFieldsQueries();
  const summary = useQuery(queries.yieldSummary(fieldId));

  useEffect(() => {
    if (summary.data?.options?.length && !value) {
      onChange(summary.data.options[0].value);
    }
  }, [summary.data?.options, onChange, value]);

  return (
    <Select
      data={summary.data?.options}
      disabled={summary.data?.options.length === 0}
      onChange={onChange}
      value={value}
    />
  );
};

export default SelectYieldMap;
