import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { ConnectivityPage, connectivityQueries } from '@connectivity';

const searchSchema = z.object({
  connectivity_link: z.number().optional(),
  code: z.string().optional(),
});

export const Route = createFileRoute('/_app/connectivity')({
  validateSearch: zodSearchValidator(searchSchema),
  component: ConnectivityPage,
  pendingComponent: ConnectivityPage.Pending,
  errorComponent: ConnectivityPage.Error,
});
