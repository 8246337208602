import { ErrorPage } from './page.error';

import { FullMainHeight } from '@ui';

export function FieldsListPage() {
  return (
    <FullMainHeight.Root>
      <FullMainHeight.Content>hello world!</FullMainHeight.Content>
    </FullMainHeight.Root>
  );
}

FieldsListPage.Error = ErrorPage;

export default FieldsListPage;
