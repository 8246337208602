export const fromBacki18n = (
  data: TranslateableObject | null = {
    i18n: {},
    name: '',
  },
  lang: string,
  fallback?: string,
) => {
  if (!data) {
    return fallback || 'missing translation';
  }
  return (
    data.i18n?.[lang] ||
    data.i18n?.['fr'] ||
    Object.values(data.i18n ?? [])[0] ||
    data.name ||
    fallback ||
    'missing translation'
  );
};

type TranslateableObject = {
  i18n: Record<string, string | null> | null;
  name: string;
};
