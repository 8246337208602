import { TFunction } from 'i18next';

export const layersOptions = (t: TFunction) => [
  {
    value: 'NDVI',
    label: t('common.ndvi'),
  },
  {
    value: 'LAI',
    label: t('common.lai'),
  },
  // {
  //   value: 'NDWI',
  //   label: 'NDWI',
  // },
  {
    value: 'RGB',
    label: t('common.rgb'),
  },
];
