import {
  NativeSelect,
  NativeSelectProps,
  Select,
  SelectProps,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

import styles from './select-map-type.module.css';

import { MAP_TYPES } from '@fields/data-access';

interface SelectMapTypeProps extends NativeSelectProps {
  maps?: {
    type: MAP_TYPES;
    numbers: number | null;
  }[];
}

export function SelectMapType({ maps, ...props }: SelectMapTypeProps) {
  const { t } = useTranslation();

  return (
    <NativeSelect
      {...props}
      className={styles.select}
      data={maps?.map((m) => ({
        value: m.type,
        label: t(`field.maps.${m.type}`),
        disabled: m.numbers === 0 || m.numbers === -1,
      }))}
    />
  );
}

export default SelectMapType;
