import { AppShell, Group, rem, Skeleton, ThemeIcon } from '@mantine/core';
import { IconHome, IconMap,IconCircleArrowUp,IconBinaryTree2 } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { Link, Outlet, useRouter } from '@tanstack/react-router';

import AppHeader from '../app-header/app-header';

import classes from './app-layout.module.css';

import { useAuth } from '@auth/data-access';
import { sharedApi } from '@data-access';

export function AppLayoutSkeleton() {
  return (
    <AppShell
      footer={{ height: 30 }}
      header={{ height: { base: 60, md: 70, lg: 80 } }}
      navbar={{
        width: { base: 200, md: 300, lg: 400 },
        breakpoint: 'sm',
      }}
      padding="md"
    >
      <AppShell.Header>
        <Skeleton h="100%" radius={0} w="100%" />
      </AppShell.Header>
      <AppShell.Navbar>
        <Skeleton h="100%" radius={0} w="100%" />
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
      <AppShell.Footer bg="gray.1"></AppShell.Footer>
    </AppShell>
  );
}

export function AppLayout() {
  const router = useRouter();
  const { logout } = useAuth();

  const cgu = useQuery({
    queryKey: ['cgu'],
    queryFn: () => sharedApi.cgu(),
  });

  const handleLogout = () => {
    logout().then(() => {
      router.invalidate();
    });
  };

  return (
    <AppShell
      footer={{ height: 60 }}
      header={{ height: { base: 60 } }}
      padding={0}
    >
      <AppShell.Header className={classes.header}>
        <AppHeader logout={handleLogout} />
      </AppShell.Header>
      <AppShell.Main bg="gray.1" className={classes.main}>
        <Outlet />
      </AppShell.Main>
      <AppShell.Footer bg="gray.1" className={classes.footer} px="md">
        <Group h="100%" justify="space-evenly">
          <Link to="/dashboard">
            <ThemeIcon color="dark" size="xl" variant="transparent">
              <IconHome />
            </ThemeIcon>
          </Link>
          <Link to="/map">
            <ThemeIcon color="dark" size="xl" variant="transparent">
              <IconMap />
            </ThemeIcon>
          </Link>
          <Link to="/prescriptions">
            <ThemeIcon color="dark" size="xl" variant="transparent">
              <IconCircleArrowUp />
            </ThemeIcon>
          </Link>
          <Link to="/connectivity">
            <ThemeIcon color="dark" size="xl" variant="transparent">
              <IconBinaryTree2 />
            </ThemeIcon>{' '}
          </Link>
        </Group>
      </AppShell.Footer>
    </AppShell>
  );
}

export default AppLayout;
