import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { DashboardPage } from '@dashboard';

const searchSchema = z.object({
  newsId: z.number().optional(),
});

export const Route = createFileRoute('/_app/dashboard')({
  validateSearch: zodSearchValidator(searchSchema),
  component: DashboardPage,
  pendingComponent: DashboardPage.Pending,
  errorComponent: DashboardPage.Error,
});
