import { Box } from '@mantine/core';
import { useLocalStorage } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useRouter } from '@tanstack/react-router';
import { useEffect } from 'react';

import { LoginForm } from '../../ui/login-form/login-form';

import styles from './login-page.module.css';

import { useAuth } from '@auth/data-access';
import { sharedApi } from '@data-access';

type LoginFormOnSubmit = React.ComponentProps<typeof LoginForm>['onSubmit'];

/* eslint-disable-next-line */
export interface LoginPageProps {}

export function LoginPage(props: LoginPageProps) {
  const [hasAcceptedTerms, setHasAcceptedTerms] = useLocalStorage({
    key: 'precifield.acceptedTerms',
    defaultValue: false,
  });
  const router = useRouter();
  const navigate = useNavigate({
    from: '/login',
  });
  const { login } = useAuth();

  const cgu = useQuery({
    queryKey: ['cgu'],
    queryFn: () => sharedApi.cgu(),
  });

  const handleSubmit: LoginFormOnSubmit = (values) => {
    if (!hasAcceptedTerms) {
      modals.openConfirmModal({
        withCloseButton: false,
        children: (
          <Box>
            J'ai lu et j'accepte les{' '}
            <a
              className={styles.link}
              href={cgu.data?.file}
              rel="noreferrer"
              target="_blank"
            >
              conditions générales d'utilisation
            </a>
            .
          </Box>
        ),
        onConfirm: () => {
          setHasAcceptedTerms(true);
          login.mutate(values);
        },
        labels: {
          confirm: 'Accepter',
          cancel: 'Refuser',
        },
      });
    } else {
      login.mutate(values);
    }
  };

  useEffect(() => {
    if (login.isSuccess) {
      login.reset();
      router.invalidate();
      navigate({
        to: '/dashboard',
      });
    }
  }, [login, login.isSuccess, navigate, router]);

  return (
    <LoginForm
      error={login.error?.message}
      isError={login.isError}
      isFetching={login.isPending || login.isSuccess}
      onSubmit={handleSubmit}
      withIcons
    />
  );
}

export default LoginPage;
