import { createFileRoute } from '@tanstack/react-router';
import { zodSearchValidator } from '@tanstack/router-zod-adapter';
import { z } from 'zod';

import { FieldsMap } from '@fields/feature';

const searchSchema = z.object({
  bbox: z.array(z.number()).length(4).optional(),
});

export const Route = createFileRoute('/_app/map')({
  validateSearch: zodSearchValidator(searchSchema),
  component: FieldsMap,
  errorComponent: FieldsMap.Error,
});
