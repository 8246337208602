import ky from 'ky';

import getAccessToken from '../utils/get-access-token';
import getCampaign from '../utils/get-campaign';

export const publicClient = ky.extend({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore - import is used by vite
  prefixUrl: import.meta.env.VITE_API_URL,
});

export const privateClient = publicClient.extend({
  hooks: {
    beforeRequest: [
      (request) => {
        const accessToken = getAccessToken();
        const locale = localStorage.getItem('i18nextLng') || 'fr';

        request.headers.set('Content-Language', locale);

        if (accessToken) {
          request.headers.set('Authorization', `Token ${accessToken}`);
          // add the campaign year to the query params
          const campaign = getCampaign();
          request.headers.set('X-Campaign-Year', '4');
        }
      },
    ],
  },
});
