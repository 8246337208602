import {
  Box,
  Button,
  Checkbox,
  Group,
  Menu,
  Radio,
  Stack,
  Text,
} from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import clsx from 'clsx';
import { EmblaCarouselType, EngineType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import { WheelGesturesPlugin } from 'embla-carousel-wheel-gestures';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { LazyLoadImage } from './lazy-load-image';
import classes from './select-image-slider.module.css';
import { usePrevNextButtons } from './use-prev-next-buttons';

type InputProps =
  | {
      multiSelect: true;
      onChange: (index: string[]) => void;
      value: string[];
      onSubValueChange?: (index: string) => void;
      subValue?: string;
    }
  | {
      multiSelect?: false;
      onChange: (index: string) => void;
      value: string;
      onSubValueChange?: (values: string[]) => void;
      subValue?: string[];
    };

/* eslint-disable-next-line */
export type SelectImageSliderProps = {
  options: {
    valueLabel: string;
    src: string;
    value: string;
  }[];
} & InputProps;

export function SelectImageSlider(props: SelectImageSliderProps) {
  const [init, setInit] = useState(false);
  const scrollListenerRef = useRef<() => void>(() => undefined);
  const listenForScrollRef = useRef(true);
  const [hasMoreToLoad, setHasMoreToLoad] = useState(true);
  const hasMoreToLoadRef = useRef(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [slidesInView, setSlidesInView] = useState<number[]>([]);
  const selectedValueIndex = props.options.findIndex(
    (option) => option.value === props.value,
  );
  const [visibleSlides, setVisibleSlides] = useState(
    props.options.slice(
      0,
      selectedValueIndex > 25 ? selectedValueIndex + 10 : 25,
    ),
  );
  const mode = props.multiSelect ? 'checkbox' : 'radio';
  const subMode = useMemo(() => {
    if (props.multiSelect && props.subValue) {
      return 'radio';
    } else if (!props.multiSelect && props.subValue) {
      return 'checkbox';
    } else {
      return null;
    }
  }, [props.multiSelect, props.subValue]);

  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      slidesToScroll: 'auto',
      dragFree: false,
      direction: 'rtl',
      watchSlides: (api) => {
        const reloadEmbla = (): void => {
          const oldEngine = api.internalEngine();

          api.reInit();
          const newEngine = api.internalEngine();
          const copyEngineModules: (keyof EngineType)[] = [
            'scrollBody',
            'location',
            'offsetLocation',
            'previousLocation',
            'target',
          ];
          copyEngineModules.forEach((engineModule) => {
            Object.assign(newEngine[engineModule], oldEngine[engineModule]);
          });

          newEngine.translate.to(oldEngine.location.get());
          const { index } = newEngine.scrollTarget.byDistance(0, false);
          newEngine.index.set(index);
          newEngine.animation.start();

          setLoadingMore(false);
          listenForScrollRef.current = true;
        };

        const reloadAfterPointerUp = (): void => {
          api.off('pointerUp', reloadAfterPointerUp);
          reloadEmbla();
        };

        const engine = api.internalEngine();

        if (hasMoreToLoadRef.current && engine.dragHandler.pointerDown()) {
          const boundsActive = engine.limit.reachedMax(engine.target.get());
          engine.scrollBounds.toggleActive(boundsActive);
          api.on('pointerUp', reloadAfterPointerUp);
        } else {
          reloadEmbla();
        }
      },
    },

    [WheelGesturesPlugin()],
  );

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  const onScroll = useCallback((api: EmblaCarouselType) => {
    if (!listenForScrollRef.current) return;

    setLoadingMore((value) => {
      const lastSlide = api.slideNodes().length - 1;
      const lastSlideInView = api.slidesInView().includes(lastSlide);
      const loadMore = !value && lastSlideInView;

      if (loadMore) {
        listenForScrollRef.current = false;

        setVisibleSlides((currentSlides) => {
          const allOptionsVisible =
            currentSlides.length === props.options.length;
          if (allOptionsVisible) {
            setHasMoreToLoad(false);
            api.off('scroll', scrollListenerRef.current);
            return currentSlides;
          }
          const nextSlides = props.options.slice(
            currentSlides.length,
            currentSlides.length + 25,
          );

          return currentSlides.concat(nextSlides);
        });

        // mockApiCall(1000, 2000, () => {
        //   setSlides((currentSlides) => {
        //     if (currentSlides.length === 20) {
        //       setHasMoreToLoad(false)
        //       api.off('scroll', scrollListenerRef.current)
        //       return currentSlides
        //     }
        //     const newSlideCount = currentSlides.length + 5
        //     return Array.from(Array(newSlideCount).keys())
        //   })
        // })
      }

      return value || lastSlideInView;
    });
  }, []);

  const addScrollListener = useCallback(
    (api: EmblaCarouselType) => {
      scrollListenerRef.current = () => onScroll(api);
      api.on('scroll', scrollListenerRef.current);
    },
    [onScroll],
  );

  useEffect(() => {
    if (!emblaApi) return;
    addScrollListener(emblaApi);

    const onResize = () => emblaApi.reInit();
    window.addEventListener('resize', onResize);
    emblaApi.on('destroy', () =>
      window.removeEventListener('resize', onResize),
    );
  }, [emblaApi, addScrollListener]);

  useEffect(() => {
    hasMoreToLoadRef.current = hasMoreToLoad;
  }, [hasMoreToLoad]);

  const Input = props.multiSelect ? Checkbox : Radio;

  const updateSlidesInView = useCallback((api: EmblaCarouselType) => {
    setSlidesInView((sIV) => {
      if (sIV.length === api.slideNodes().length) {
        api.off('slidesInView', updateSlidesInView);
      }
      const inView = api.slidesInView().filter((index) => !sIV.includes(index));
      // const inView = api.slidesInView();

      return sIV.concat(inView);
    });
  }, []);

  useEffect(() => {
    if (emblaApi) {
      updateSlidesInView(emblaApi);

      emblaApi.on('slidesInView', updateSlidesInView);
      emblaApi.on('reInit', updateSlidesInView);
    }
  }, [emblaApi, updateSlidesInView]);

  useEffect(() => {
    if (
      props.value &&
      !init &&
      emblaApi?.slideNodes() &&
      selectedValueIndex > -1
    ) {
      const { slideRegistry } = emblaApi.internalEngine();
      const snapIndexThatSlideBelongsTo = slideRegistry.findIndex((group) =>
        group.includes(selectedValueIndex),
      );

      if (typeof snapIndexThatSlideBelongsTo !== 'undefined') {
        emblaApi.scrollTo(snapIndexThatSlideBelongsTo, true);
      }
      setInit(true);
      emblaApi.reInit();
    }
  }, [
    props.value,
    emblaApi,
    onNextButtonClick,
    init,
    visibleSlides,
    selectedValueIndex,
  ]);

  return (
    <Group className={classes['container']} gap={0} wrap="nowrap">
      <Button
        className={classes.button}
        disabled={nextBtnDisabled}
        onClick={onNextButtonClick}
        size="compact-sm"
      >
        <IconChevronLeft />
      </Button>
      <Input.Group
        className={classes.radioGroup}
        dir="rtl"
        onChange={props.onChange as any}
        value={props.value as any}
      >
        <section className={classes.embla} ref={emblaRef}>
          <div className={classes.embla__container}>
            {visibleSlides.map((option, index) => (
              <Menu
                closeDelay={100}
                disabled={subMode !== 'checkbox'}
                key={option.value}
                openDelay={100}
                trigger="hover"
              >
                <Menu.Target>
                  <Input.Card
                    className={clsx(classes.embla__slide, {
                      [classes['embla__slide--active']]:
                        option.value === props.value,
                      [classes.multiSelection]:
                        props.multiSelect && props.subValue !== option.value,
                      [classes.singleSelection]:
                        props.multiSelect && props.subValue === option.value,
                    })}
                    onContextMenu={(e) => {
                      if (props.multiSelect && props.onSubValueChange) {
                        e.preventDefault();
                        props.onSubValueChange(option.value);
                      }
                    }}
                    value={option.value}
                  >
                    <Stack gap="x2s" h="100%" pt="xs" px="xs">
                      <Box h="80%">
                        {/* {slidesInView.indexOf(index) > -1 ? ( */}
                        <LazyLoadImage
                          alt={option.valueLabel}
                          inView={slidesInView.indexOf(index) > -1}
                          index={0}
                          src={option.src}
                        />
                        {/* ) : null} */}
                      </Box>
                      <Group flex="0 0 auto" gap="x2s" wrap="nowrap">
                        {subMode === 'checkbox' ? (
                          <Checkbox.Indicator
                            checked={
                              !props.multiSelect &&
                              props.subValue?.includes(option.value)
                            }
                            size="xs"
                          />
                        ) : (
                          <Input.Indicator size="xs" />
                        )}

                        <Text size="sm">{option.valueLabel}</Text>
                      </Group>
                    </Stack>
                  </Input.Card>
                </Menu.Target>
                <Menu.Dropdown>
                  <Menu.Item
                    leftSection={
                      <Checkbox.Indicator
                        checked={
                          !props.multiSelect &&
                          props.subValue?.includes(option.value)
                        }
                        size="xs"
                      />
                    }
                    onClick={() => {
                      if (!props.multiSelect) {
                        // draflist const that add or remove the value
                        const draftList = props.subValue?.includes(option.value)
                          ? props.subValue.filter(
                              (value) => value !== option.value,
                            )
                          : [...(props.subValue || []), option.value];
                        props.onSubValueChange?.(draftList);
                      }
                    }}
                  >
                    <Text size="sm">Sélectionner la carte</Text>
                  </Menu.Item>
                </Menu.Dropdown>
              </Menu>
            ))}
          </div>
        </section>
      </Input.Group>
      <Button
        className={classes.button}
        disabled={prevBtnDisabled}
        onClick={onPrevButtonClick}
        size="compact-sm"
      >
        <IconChevronRight />
      </Button>
    </Group>
  );
}

export default SelectImageSlider;
