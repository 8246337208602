import { Group, ActionIcon, Image, Box } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

import classes from './app-header.module.css';

import { myPrecifieldLogo2, precifieldLogoIcon } from '@assets';
import { LangSelect } from '@ui';

interface AppHeaderProps {
  logout: () => void;
}

export function AppHeader({ logout }: AppHeaderProps) {
  const { t } = useTranslation();

  return (
    <Group className={classes.container} h="100%" justify="space-between">
      <Group h="100%">
        <Box className={classes.logoContainer}>
          <Image
            alt="MyPrecifield"
            fit="contain"
            src={myPrecifieldLogo2}
            visibleFrom="md"
          />
          <Image
            alt="MyPrecifield"
            fit="contain"
            hiddenFrom="md"
            src={precifieldLogoIcon}
          />
        </Box>
      </Group>
      <Group>
        <Group>
          <LangSelect />
        </Group>
        <Group>
          <ActionIcon
            aria-label={t('auth.logout')}
            color="white"
            onClick={logout}
            size={40}
            variant="subtle"
          >
            <IconLogout
              stroke={1.5}
              style={{ width: '100%', height: '100%' }}
            />
          </ActionIcon>
        </Group>
      </Group>
    </Group>
  );
}

export default AppHeader;
