import { ComboboxItem } from '@mantine/core';

import { ConnectivityResponse } from '../types/connectivity';
import { ConsoleResponse } from '../types/console';
import { manageConsolesDto } from '../types/dto';

import { handleRequest, privateClient } from '@data-access';

export const connectivityApi = {
  getConnectivity: async () => {
    const res = await handleRequest<ConnectivityResponse>(
      privateClient.get('connectivity/').json(),
    );

    return res;
  },
  getConsoles: async () => {
    const res = await handleRequest<ConsoleResponse>(
      privateClient.get('connectivity/consoles/').json(),
    );

    const options = res.consoles.reduce(
      (acc, console) => {
        if (!acc[console.manufacturer]) {
          acc[console.manufacturer] = [];
        }
        acc[console.manufacturer].push({
          value: console.id.toString(),
          label: console.name,
        });
        return acc;
      },
      {} as Record<string, ComboboxItem[]>,
    );

    return { ...res, options };
  },
  manageConsoles: async (dto: manageConsolesDto) => {
    const res = await handleRequest<{ success: boolean }>(
      privateClient.patch('connectivity/consoles/', { json: dto }).json(),
    );

    return res;
  },
  importConnectivity: async (params: {
    file: File;
    id: number;
    farm: number;
  }) => {
    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('farm', params.farm.toString());

    const res = await handleRequest<{
      status: string;
      async_computation_request_id: number;
    }>(
      privateClient
        .post(`connectivity/${params.id}/`, {
          body: formData,
        })
        .json(),
    );

    return res;
  },
  getImportStatus: async (id: number) => {
    const res = await handleRequest<{
      id: number;
      status: string;
    }>(privateClient.get(`computation_requests/${id}/`).json());

    return res;
  },
  getFarms: async () => {
    const res = await handleRequest<{ id: number; name: string }[]>(
      privateClient.get('farms/').json(),
    );

    return res.map((farm) => ({
      value: farm.id.toString(),
      label: farm.name,
    }));
  },

  getSsoUrl: async (params: { connectivity_link: number }) => {
    const res = await handleRequest<{ url: string }>(
      privateClient
        .get(`connectivity/${params.connectivity_link}/auth/`)
        .json(),
    );

    return res;
  },
  connectSso: async (params: {
    code: string;
    connectivity_link: number;
    user_accept: boolean;
    automatic: boolean;
    sync_fields: string;
  }) => {
    const { code, connectivity_link, ...data } = params;
    const res = await handleRequest<{ success: boolean }>(
      privateClient
        .post(`connectivity/${connectivity_link}/auth/`, {
          searchParams: { code: code },
          json: data,
        })
        .json(),
    );

    return res;
  },
};
