import { Select } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';

import { SelectMapProps } from '../select-map';

import { useFieldsQueries } from '@fields/data-access';

const SelectSoilmap: React.FC<SelectMapProps> = ({
  fieldId,
  onChange,
  value,
}) => {
  const queries = useFieldsQueries();
  const summary = useQuery(queries.soilSummary(fieldId));

  useEffect(() => {
    if (summary.data?.options?.length && !value) {
      onChange(summary.data.options[0].value);
    }
  }, [summary.data?.options, onChange, value]);

  return (
    <Select data={summary.data?.options} onChange={onChange} value={value} />
  );
};

export default SelectSoilmap;
