import { AspectRatio, Modal, ScrollArea, Stack } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { useNavigate, useSearch } from '@tanstack/react-router';
import { useState } from 'react';

import { NewsCard } from '../../components/news-card/news-card';
import { dashboardQueries } from '../../data-access/queries/dashboard.queries';

import { PageError } from './page.error';
import classes from './page.module.css';
import { PendingPage } from './page.pending';

import { useQueriesWithGlobal } from '@data-access';

export function DashboardPage() {
  const { newsId } = useSearch({
    from: '/_app/dashboard',
  });
  const navigate = useNavigate({
    from: '/dashboard',
  });
  const queries = useQueriesWithGlobal(dashboardQueries);
  const news = useQuery(queries.news());

  const currentNews = news.data?.find((item) => item.id === newsId);

  const handleReadMore = (id: number) => {
    navigate({ search: { newsId: id } });
  };

  const handleCloseModal = () => {
    navigate({ search: undefined, replace: true });
  };

  return (
    <>
      <Stack p="md">
        {news.data?.map((item, index) => (
          <NewsCard key={index} {...item} onReadMore={handleReadMore} />
        ))}
      </Stack>
      <Modal
        classNames={{
          inner: classes.modalInner,
          content: classes.modalContent,
        }}
        fullScreen
        onClose={handleCloseModal}
        opened={currentNews !== undefined}
        overlayProps={{
          backgroundOpacity: 0,
        }}
        title={currentNews?.title}
      >
        <AspectRatio flex="0 0 auto" ratio={500 / 400}>
          <img alt={currentNews?.title} height={180} src={currentNews?.image} />
        </AspectRatio>
        {currentNews && (
          <div
            dangerouslySetInnerHTML={{ __html: currentNews.long_description }}
          />
        )}
      </Modal>
    </>
  );
}

DashboardPage.Error = PageError;
DashboardPage.Pending = PendingPage;

export default DashboardPage;
