import { ProductRel, VarietyRel } from '@data-access';

export type CommonAdvicesResponse = {
  results: CommonAdvice[];
  count: number;
  next: string | null;
  previous: string | null;
};

export interface CommonAdvice {
  id: number;
  yields: Yield[] | null;
  product?: ProductRel;
  variety?: VarietyRel;
  field: Field;
  created_at: string;
  updated_at: string;
  is_valid: boolean;
  status: string;
  seed_date?: string;
  yield_potential?: string;
  olympic_yield?: number;
  X_dose?: number;
  nitrogen_already_applied?: number;
}

export interface Yield {
  id: number;
  cloud?: boolean;
  mean_yield?: number;
  mean_dose?: number;
  date: string;
  computed: boolean;
  status: FORM_STATUS;
}

interface Field {
  id: number;
  thumbnail: string;
  name: string;
  farm: Farm;
  area: number;
}

interface Farm {
  id: number;
  name: string;
}

export enum FORM_STATUS {
  'PENDING' = 'PENDING',
  'FINISHED' = 'FINISHED',
  'INCOMPLETE' = 'INCOMPLETE',
  'ERROR' = 'ERROR',
  'WAITING' = 'WAITING',
}
