import {
  Button,
  Group,
  Paper,
  rem,
  SimpleGrid,
  Skeleton,
  Stack,
  Title,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { ActionsMenu } from '@ui';

export function PendingPage() {
  const { t } = useTranslation();

  return (
    <Stack p="md">
      <Paper>
        <Group justify="space-between">
          <Title order={2}>{t('connectivity.consoles-title')}</Title>
          <Group>
            <Button disabled>{t('common.add')}</Button>
            <ActionsMenu.Button disabled />
          </Group>
        </Group>
      </Paper>
      <SimpleGrid
        cols={{
          base: 1,
          sm: 2,
          md: 3,
          lg: 4,
        }}
      >
        {[0, 1].map((i) => (
          <Skeleton h={rem(83)} key={i} />
        ))}
      </SimpleGrid>
    </Stack>
  );
}

export default PendingPage;
