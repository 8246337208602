import { area as tArea } from '@turf/area';
import { centerOfMass } from '@turf/center-of-mass';
import { distance } from '@turf/distance';
import {
  convertArea,
  point,
  geometry,
  feature as tFeature,
  featureCollection,
} from '@turf/helpers';
import { midpoint } from '@turf/midpoint';
import { Feature, Point, Position } from 'geojson';
import { MapEvent } from 'mapbox-gl';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Source, Layer, useMap } from 'react-map-gl';

import styles from './feature-size.module.css';

/* eslint-disable-next-line */
export interface FeatureSizeProps {
  features?: GeoJSON.Feature<GeoJSON.Polygon>[];
  withBorders?: boolean;
}

type BorderLength = {
  length: number;
  coordinates: Position;
};

type Area = Feature<Point, { area: string }>;

export function FeatureSize({
  features,
  withBorders = true,
}: FeatureSizeProps) {
  const { current: map } = useMap();
  const polygons = useMemo(
    () =>
      features?.filter(
        (feature) =>
          feature.geometry.type === 'Polygon' ||
          feature.geometry.type === 'MultiPolygon',
      ) || [],
    [features],
  );

  const [zoom, setZoom] = useState<number>(map?.getZoom() || 0);
  const [area, setArea] = useState<Area[]>([]);
  const [bordersLength, setBordersLength] = useState<BorderLength[]>([]);
  const [bordersInBounds, setBorderInBounds] = useState<BorderLength[]>([]);

  const getBordersInBounds = useCallback(() => {
    if (!map) {
      return [];
    }
    const bounds = map.getBounds();
    const newBordersInBounds = bordersLength.filter((border) =>
      bounds?.contains([border.coordinates[0], border.coordinates[1]]),
    );

    const zoomLevel = map.getZoom();
    let minLength = 15; // Adjust the threshold based on zoom level
    if (zoomLevel > 19.5) {
      minLength = 2;
    } else if (zoomLevel > 17) {
      minLength = 5;
    } else if (zoomLevel > 16) {
      minLength = 10;
    }
    const filteredBorders = newBordersInBounds.filter(
      (border) => border.length >= minLength,
    );

    setBorderInBounds(filteredBorders);
  }, [bordersLength, map]);

  useEffect(() => {
    if (map) {
      const handleZoom = (e: MapEvent) => {
        setZoom(e.target.getZoom());
      };
      map.on('zoom', handleZoom);

      return () => {
        map.off('zoom', handleZoom);
      };
    }
  }, [map]);

  useEffect(() => {
    if (map) {
      const handleMoveEnd = (e: MapEvent) => {
        getBordersInBounds();
      };
      map.on('moveend', handleMoveEnd);

      return () => {
        map.off('moveend', handleMoveEnd);
      };
    }
  }, [getBordersInBounds, map]);

  useEffect(() => {
    getBordersInBounds();
  }, [getBordersInBounds]);

  useEffect(() => {
    if (polygons && polygons.length > 0) {
      const areas: Area[] = [];
      const newBordersLength: BorderLength[] = [];

      polygons.forEach((feature) => {
        if (
          feature &&
          feature.geometry.type === 'Polygon' &&
          feature.geometry.coordinates.length
        ) {
          try {
            // calculate the area of the drawn plot using Turf.js
            const polArea = tArea(feature);
            const areaAcres = convertArea(
              polArea,
              'meters',
              'hectares',
            ).toFixed(2);
            const featureCenter = centerOfMass(feature).geometry.coordinates;

            areas.push(
              tFeature(geometry('Point', featureCenter), {
                area: areaAcres,
              }) as Area,
            );

            // loop through the coordinates of the polygon and calculate the length of each side
            const coordinates = feature.geometry.coordinates[0];

            for (let i = 0; i < coordinates.length - 1; i++) {
              const from = point(coordinates[i]);
              const to = point(coordinates[i + 1]);
              const length = distance(from, to, { units: 'meters' });

              const lineCenter = midpoint(from, to);
              const lineCenterCoordinates = lineCenter.geometry.coordinates;
              const borderLength: BorderLength = {
                length,
                coordinates: lineCenterCoordinates,
              };
              newBordersLength.push(borderLength);
            }
          } catch (_) {
            // draw can return a polygon with no coordinates. We ignore these to avoid crashing the app
          }
        }
      });

      setArea(areas);
      setBordersLength(newBordersLength);
    } else {
      setBordersLength([]);
      setArea([]);
    }
  }, [polygons]);

  const borderLayers = bordersInBounds.map((border, i) => ({
    id: `border-layer-${i}`,
    type: 'symbol' as const,

    layout: {
      'text-field': `${border.length.toFixed()} m`,
      'text-size': 12,
      'icon-image': 'rounded' as const,
      'icon-text-fit': 'both' as const,
    },
    paint: {
      'text-color': '#fff',
    },
  }));

  return (
    <>
      {area && (
        <Source
          data={featureCollection(area)}
          id={`area-source`}
          type="geojson"
        >
          <Layer
            beforeId="z-index-2"
            id="area-layer"
            layout={{
              'text-field': ['concat', ['to-string', ['get', 'area']], ' Ha'],
              'text-size': 14,
              'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
              'icon-image': 'rounded',
              'icon-text-fit': 'both',
            }}
            paint={{
              'text-color': '#fff',
            }}
            type="symbol"
          />
        </Source>
      )}
      {zoom > 14 &&
        withBorders &&
        borderLayers.map((layer, i) => (
          <Source
            data={{
              type: 'Feature',
              geometry: {
                type: 'Point',
                coordinates: bordersInBounds[i].coordinates,
              },
            }}
            id={`border-source-${i}`}
            key={layer.id}
            type="geojson"
          >
            <Layer {...layer} beforeId="z-index-2" />
          </Source>
        ))}
    </>
  );
}

export default FeatureSize;
