import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-react-table/styles.css';
import '@mantine/notifications/styles.css';
import '@mantine/charts/styles.css';
import '../styles.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { SafeArea } from '@capacitor-community/safe-area';
import { DatesProvider } from '@mantine/dates';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import 'dayjs/locale/fr';
import 'dayjs/locale/en';

import { MyRouterContext } from '../main';

import { campaignAtom, useCampaigns } from '@data-access';
import { DefaultPendingComponent, ReloadPrompt } from '@ui';

SafeArea.enable({
  config: {
    customColorsForSystemBars: true,
    statusBarColor: '#000000', // transparent
    statusBarContent: 'light',
    navigationBarColor: '#000000', // transparent
    navigationBarContent: 'light',
  },
});

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
          // For Embedded Mode
          // default: res.TanStackRouterDevtoolsPanel
        })),
      );

const ReactQueryDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : lazy(() =>
        // Lazy load in development
        import('@tanstack/react-query-devtools').then((res) => ({
          default: res.ReactQueryDevtools,
        })),
      );

dayjs.locale('fr');

export const Route = createRootRouteWithContext<MyRouterContext>()({
  component: RootComponent,
  pendingComponent: DefaultPendingComponent,
});

function RootComponent() {
  const { i18n } = useTranslation();
  const [campaign, setCampaign] = useAtom(campaignAtom);
  const nullEntry: any[] = [];
  const [notifications, setnotifications] = useState(nullEntry);
  const campaigns = useCampaigns();

  // on init select campaign for current year
  useEffect(() => {
    if (campaigns.data) {
      if (!campaign) {
        setCampaign(
          campaigns.data.find((c) => c.year === new Date().getFullYear())?.id ||
            campaigns.data[0].id,
        );
      }
    }
  }, [campaigns.data, campaign, setCampaign]);

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  useEffect(() => {
    const register = () => {
      console.log('Initializing HomePage');

      // Register with Apple / Google to receive push via APNS/FCM
      PushNotifications.register();

      // On success, we should be able to receive notifications
      PushNotifications.addListener('registration', (token: Token) => {
        showToast('registration_success', 'Push registration success');
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener('registrationError', (error: any) => {
        alert('Error on registration: ' + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          setnotifications((n) => [
            ...n,
            {
              id: notification.id,
              title: notification.title,
              body: notification.body,
              type: 'foreground',
            },
          ]);
        },
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          setnotifications((n) => [
            ...n,
            {
              id: notification.notification.data.id,
              title: notification.notification.data.title,
              body: notification.notification.data.body,
              type: 'action',
            },
          ]);
        },
      );
    };

    PushNotifications.checkPermissions().then((res) => {
      if (res.receive !== 'granted') {
        PushNotifications.requestPermissions().then((r) => {
          if (r.receive === 'denied') {
            showToast(
              'permission_denied',
              'Push Notification permission denied',
            );
          } else {
            showToast(
              'permission_granted',
              'Push Notification permission granted',
            );
            register();
          }
        });
      } else {
        register();
      }
    });
  }, []);

  const showToast = async (id: string, msg: string) => {
    // Notifications.show({
    //   id,
    //   message: msg,
    // });
  };

  return (
    <>
      <ReloadPrompt />
      <ReactQueryDevtools buttonPosition="top-left" initialIsOpen={false} />
      <TanStackRouterDevtools position="bottom-right" />
      <Notifications />
      <ModalsProvider>
        <DatesProvider
          settings={{
            locale: i18n.language,
          }}
        >
          <Outlet />
        </DatesProvider>
      </ModalsProvider>
    </>
  );
}
