import { booleanPointInPolygon } from '@turf/boolean-point-in-polygon';
import { buffer } from '@turf/buffer';
import { difference } from '@turf/difference';
import { featureCollection, point, polygon } from '@turf/helpers';
import { Feature, LineString, Polygon } from 'geojson';

export enum TurfSplitErrors {
  INVALID_POLYGON_INPUT = 'invalid-polygon-input',
  INVALID_LINE_INPUT = 'invalid-line-input',
  LINE_INTERSECTS_POLYGON_BOUNDARY = 'line-intersects-polygon-boundary',
}

export function turfSplit(
  poly: Feature<Polygon>,
  line: Feature<LineString>,
  properties: Record<string, any> = {},
) {
  // validation
  if (poly.geometry === void 0 || poly.geometry.type !== 'Polygon') {
    throw new Error(TurfSplitErrors.INVALID_POLYGON_INPUT);
  }
  if (line.geometry === void 0 || line.geometry.type !== 'LineString') {
    throw new Error(TurfSplitErrors.INVALID_LINE_INPUT);
  }
  // updated from inside to booleanPointInPolygon 8/22 JM
  if (
    booleanPointInPolygon(point(line.geometry.coordinates[0]), poly) ||
    booleanPointInPolygon(
      point(line.geometry.coordinates[line.geometry.coordinates.length - 1]),
      poly,
    )
  ) {
    throw new Error(TurfSplitErrors.LINE_INTERSECTS_POLYGON_BOUNDARY);
  }

  // erase replaced by difference and buffer function changed significantly
  const _axe = buffer(line, 0.001, { units: 'meters' });

  if (!_axe) {
    return;
  }

  const _body = difference(featureCollection([poly, _axe]));

  const pieces = [];

  if (_body?.geometry.type === 'Polygon') {
    pieces.push(polygon(_body.geometry.coordinates));
  } else if (_body) {
    _body.geometry.coordinates.forEach((a) => pieces.push(polygon(a)));
  }

  pieces.forEach(
    (a) =>
      (a.properties = {
        ...poly.properties,
        ...properties,
      }),
  );

  return featureCollection(pieces);
}
