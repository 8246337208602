import {
  AspectRatio,
  Box,
  Button,
  Card,
  Group,
  Image,
  Stack,
  Text,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

import { News } from '../../data-access/types';

import classes from './news-card.module.css';

interface NewsCardProps extends News {
  onReadMore: (id: number) => void;
}

export function NewsCard({
  id,
  title,
  short_description,
  link,
  image,
  onReadMore,
}: NewsCardProps) {
  const { t } = useTranslation();

  return (
    <Card>
      <Card.Section>
        <Image alt={title} height={160} src={image} />
      </Card.Section>
      <Stack justify="space-between" mt="sm" w="100%">
        <Box>
          <Text className={classes.title}>{title}</Text>
          <Text>{short_description}</Text>
        </Box>
        {/* <Button fullWidth onClick={() => onReadMore(id)}>
          {t('dashboard.read-more')}
        </Button> */}
        <Button component="a" href={link} target="_blank">
          {t('dashboard.read-more')}
        </Button>
      </Stack>
    </Card>
  );
}

export default NewsCard;
