import {
  Box,
  Button,
  Checkbox,
  Group,
  Image,
  Paper,
  Stack,
  Text,
} from '@mantine/core';
import { useTranslation } from 'react-i18next';

import classes from './console-card.module.css';

const root = import.meta.env.VITE_API_ROOT;

interface ConsoleCardProps {
  name: string;
  manufacturer?: string;
  image: string;
  link: string;
  id: string;
}

export function ConsoleCard({
  name,
  manufacturer,
  image,
  link,
  id,
}: ConsoleCardProps) {
  const { t } = useTranslation();

  return (
    <Paper p={0}>
      <Checkbox.Card className={classes.card} value={id}>
        <Group>
          <Image alt={name} fit="cover" h={75} src={root + image} w={75} />
          <Stack flex="1 1 auto">
            <Group justify="space-between">
              <Box>
                <Text fw="bold">{name}</Text>
                <Text lh={0.4} size="xs">
                  {manufacturer}
                </Text>
              </Box>
              <Checkbox.Indicator />
            </Group>
            <Button
              component="a"
              href={root + link}
              ml="auto"
              onClick={(event) => {
                event.stopPropagation();
              }}
              rel="noreferrer"
              size="compact-sm"
              target="_blank"
            >
              {t('connectivity.tutorial')}
            </Button>
          </Stack>
        </Group>
      </Checkbox.Card>
    </Paper>
  );
}

export default ConsoleCard;
