import type { Position } from '@capacitor/geolocation';
import { useControl } from 'react-map-gl';

import MapboxGeolocateControl from './mapbox-geolocate-control';

/* eslint-disable-next-line */
export interface GeolocateControlProps {
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  geolocateOnInit?: boolean;
  onPositionChange: (position: Position) => void;
}

export function GeolocateControl(props: GeolocateControlProps) {
  useControl(
    () =>
      new MapboxGeolocateControl({
        onPositionChange: props.onPositionChange,
      }) as any,
    {
      position: props.position,
    },
  );

  return null;
}

export default GeolocateControl;
