import { useMutation } from '@tanstack/react-query';

import { connectivityApi } from '../../apis/connectivity';

export function useManageConsoles() {
  return useMutation({
    mutationFn: connectivityApi.manageConsoles,
  });
}

export default useManageConsoles;
