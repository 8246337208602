/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router'

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as AuthImport } from './routes/_auth'
import { Route as AppImport } from './routes/_app'
import { Route as IndexImport } from './routes/index'
import { Route as AuthResetPasswordImport } from './routes/_auth/reset-password'
import { Route as AuthLoginImport } from './routes/_auth/login'
import { Route as AppSupportImport } from './routes/_app/support'
import { Route as AppPrescriptionsImport } from './routes/_app/prescriptions'
import { Route as AppMapImport } from './routes/_app/map'
import { Route as AppFieldsImport } from './routes/_app/fields'
import { Route as AppDashboardImport } from './routes/_app/dashboard'
import { Route as AppConnectivityImport } from './routes/_app/connectivity'

// Create Virtual Routes

const AboutLazyImport = createFileRoute('/about')()
const AuthLostPasswordLazyImport = createFileRoute('/_auth/lost-password')()
const AppSettingsLazyImport = createFileRoute('/_app/settings')()
const AppProfileLazyImport = createFileRoute('/_app/profile')()

// Create/Update Routes

const AboutLazyRoute = AboutLazyImport.update({
  id: '/about',
  path: '/about',
  getParentRoute: () => rootRoute,
} as any).lazy(() => import('./routes/about.lazy').then((d) => d.Route))

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const AppRoute = AppImport.update({
  id: '/_app',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const AuthLostPasswordLazyRoute = AuthLostPasswordLazyImport.update({
  id: '/lost-password',
  path: '/lost-password',
  getParentRoute: () => AuthRoute,
} as any).lazy(() =>
  import('./routes/_auth/lost-password.lazy').then((d) => d.Route),
)

const AppSettingsLazyRoute = AppSettingsLazyImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AppRoute,
} as any).lazy(() => import('./routes/_app/settings.lazy').then((d) => d.Route))

const AppProfileLazyRoute = AppProfileLazyImport.update({
  id: '/profile',
  path: '/profile',
  getParentRoute: () => AppRoute,
} as any).lazy(() => import('./routes/_app/profile.lazy').then((d) => d.Route))

const AuthResetPasswordRoute = AuthResetPasswordImport.update({
  id: '/reset-password',
  path: '/reset-password',
  getParentRoute: () => AuthRoute,
} as any)

const AuthLoginRoute = AuthLoginImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => AuthRoute,
} as any)

const AppSupportRoute = AppSupportImport.update({
  id: '/support',
  path: '/support',
  getParentRoute: () => AppRoute,
} as any)

const AppPrescriptionsRoute = AppPrescriptionsImport.update({
  id: '/prescriptions',
  path: '/prescriptions',
  getParentRoute: () => AppRoute,
} as any)

const AppMapRoute = AppMapImport.update({
  id: '/map',
  path: '/map',
  getParentRoute: () => AppRoute,
} as any)

const AppFieldsRoute = AppFieldsImport.update({
  id: '/fields',
  path: '/fields',
  getParentRoute: () => AppRoute,
} as any)

const AppDashboardRoute = AppDashboardImport.update({
  id: '/dashboard',
  path: '/dashboard',
  getParentRoute: () => AppRoute,
} as any)

const AppConnectivityRoute = AppConnectivityImport.update({
  id: '/connectivity',
  path: '/connectivity',
  getParentRoute: () => AppRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/_app': {
      id: '/_app'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AppImport
      parentRoute: typeof rootRoute
    }
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/about': {
      id: '/about'
      path: '/about'
      fullPath: '/about'
      preLoaderRoute: typeof AboutLazyImport
      parentRoute: typeof rootRoute
    }
    '/_app/connectivity': {
      id: '/_app/connectivity'
      path: '/connectivity'
      fullPath: '/connectivity'
      preLoaderRoute: typeof AppConnectivityImport
      parentRoute: typeof AppImport
    }
    '/_app/dashboard': {
      id: '/_app/dashboard'
      path: '/dashboard'
      fullPath: '/dashboard'
      preLoaderRoute: typeof AppDashboardImport
      parentRoute: typeof AppImport
    }
    '/_app/fields': {
      id: '/_app/fields'
      path: '/fields'
      fullPath: '/fields'
      preLoaderRoute: typeof AppFieldsImport
      parentRoute: typeof AppImport
    }
    '/_app/map': {
      id: '/_app/map'
      path: '/map'
      fullPath: '/map'
      preLoaderRoute: typeof AppMapImport
      parentRoute: typeof AppImport
    }
    '/_app/prescriptions': {
      id: '/_app/prescriptions'
      path: '/prescriptions'
      fullPath: '/prescriptions'
      preLoaderRoute: typeof AppPrescriptionsImport
      parentRoute: typeof AppImport
    }
    '/_app/support': {
      id: '/_app/support'
      path: '/support'
      fullPath: '/support'
      preLoaderRoute: typeof AppSupportImport
      parentRoute: typeof AppImport
    }
    '/_auth/login': {
      id: '/_auth/login'
      path: '/login'
      fullPath: '/login'
      preLoaderRoute: typeof AuthLoginImport
      parentRoute: typeof AuthImport
    }
    '/_auth/reset-password': {
      id: '/_auth/reset-password'
      path: '/reset-password'
      fullPath: '/reset-password'
      preLoaderRoute: typeof AuthResetPasswordImport
      parentRoute: typeof AuthImport
    }
    '/_app/profile': {
      id: '/_app/profile'
      path: '/profile'
      fullPath: '/profile'
      preLoaderRoute: typeof AppProfileLazyImport
      parentRoute: typeof AppImport
    }
    '/_app/settings': {
      id: '/_app/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AppSettingsLazyImport
      parentRoute: typeof AppImport
    }
    '/_auth/lost-password': {
      id: '/_auth/lost-password'
      path: '/lost-password'
      fullPath: '/lost-password'
      preLoaderRoute: typeof AuthLostPasswordLazyImport
      parentRoute: typeof AuthImport
    }
  }
}

// Create and export the route tree

interface AppRouteChildren {
  AppConnectivityRoute: typeof AppConnectivityRoute
  AppDashboardRoute: typeof AppDashboardRoute
  AppFieldsRoute: typeof AppFieldsRoute
  AppMapRoute: typeof AppMapRoute
  AppPrescriptionsRoute: typeof AppPrescriptionsRoute
  AppSupportRoute: typeof AppSupportRoute
  AppProfileLazyRoute: typeof AppProfileLazyRoute
  AppSettingsLazyRoute: typeof AppSettingsLazyRoute
}

const AppRouteChildren: AppRouteChildren = {
  AppConnectivityRoute: AppConnectivityRoute,
  AppDashboardRoute: AppDashboardRoute,
  AppFieldsRoute: AppFieldsRoute,
  AppMapRoute: AppMapRoute,
  AppPrescriptionsRoute: AppPrescriptionsRoute,
  AppSupportRoute: AppSupportRoute,
  AppProfileLazyRoute: AppProfileLazyRoute,
  AppSettingsLazyRoute: AppSettingsLazyRoute,
}

const AppRouteWithChildren = AppRoute._addFileChildren(AppRouteChildren)

interface AuthRouteChildren {
  AuthLoginRoute: typeof AuthLoginRoute
  AuthResetPasswordRoute: typeof AuthResetPasswordRoute
  AuthLostPasswordLazyRoute: typeof AuthLostPasswordLazyRoute
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthLoginRoute: AuthLoginRoute,
  AuthResetPasswordRoute: AuthResetPasswordRoute,
  AuthLostPasswordLazyRoute: AuthLostPasswordLazyRoute,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/connectivity': typeof AppConnectivityRoute
  '/dashboard': typeof AppDashboardRoute
  '/fields': typeof AppFieldsRoute
  '/map': typeof AppMapRoute
  '/prescriptions': typeof AppPrescriptionsRoute
  '/support': typeof AppSupportRoute
  '/login': typeof AuthLoginRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/profile': typeof AppProfileLazyRoute
  '/settings': typeof AppSettingsLazyRoute
  '/lost-password': typeof AuthLostPasswordLazyRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '': typeof AuthRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/connectivity': typeof AppConnectivityRoute
  '/dashboard': typeof AppDashboardRoute
  '/fields': typeof AppFieldsRoute
  '/map': typeof AppMapRoute
  '/prescriptions': typeof AppPrescriptionsRoute
  '/support': typeof AppSupportRoute
  '/login': typeof AuthLoginRoute
  '/reset-password': typeof AuthResetPasswordRoute
  '/profile': typeof AppProfileLazyRoute
  '/settings': typeof AppSettingsLazyRoute
  '/lost-password': typeof AuthLostPasswordLazyRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/_app': typeof AppRouteWithChildren
  '/_auth': typeof AuthRouteWithChildren
  '/about': typeof AboutLazyRoute
  '/_app/connectivity': typeof AppConnectivityRoute
  '/_app/dashboard': typeof AppDashboardRoute
  '/_app/fields': typeof AppFieldsRoute
  '/_app/map': typeof AppMapRoute
  '/_app/prescriptions': typeof AppPrescriptionsRoute
  '/_app/support': typeof AppSupportRoute
  '/_auth/login': typeof AuthLoginRoute
  '/_auth/reset-password': typeof AuthResetPasswordRoute
  '/_app/profile': typeof AppProfileLazyRoute
  '/_app/settings': typeof AppSettingsLazyRoute
  '/_auth/lost-password': typeof AuthLostPasswordLazyRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | ''
    | '/about'
    | '/connectivity'
    | '/dashboard'
    | '/fields'
    | '/map'
    | '/prescriptions'
    | '/support'
    | '/login'
    | '/reset-password'
    | '/profile'
    | '/settings'
    | '/lost-password'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | ''
    | '/about'
    | '/connectivity'
    | '/dashboard'
    | '/fields'
    | '/map'
    | '/prescriptions'
    | '/support'
    | '/login'
    | '/reset-password'
    | '/profile'
    | '/settings'
    | '/lost-password'
  id:
    | '__root__'
    | '/'
    | '/_app'
    | '/_auth'
    | '/about'
    | '/_app/connectivity'
    | '/_app/dashboard'
    | '/_app/fields'
    | '/_app/map'
    | '/_app/prescriptions'
    | '/_app/support'
    | '/_auth/login'
    | '/_auth/reset-password'
    | '/_app/profile'
    | '/_app/settings'
    | '/_auth/lost-password'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  AppRoute: typeof AppRouteWithChildren
  AuthRoute: typeof AuthRouteWithChildren
  AboutLazyRoute: typeof AboutLazyRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AppRoute: AppRouteWithChildren,
  AuthRoute: AuthRouteWithChildren,
  AboutLazyRoute: AboutLazyRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_app",
        "/_auth",
        "/about"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_app": {
      "filePath": "_app.tsx",
      "children": [
        "/_app/connectivity",
        "/_app/dashboard",
        "/_app/fields",
        "/_app/map",
        "/_app/prescriptions",
        "/_app/support",
        "/_app/profile",
        "/_app/settings"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/login",
        "/_auth/reset-password",
        "/_auth/lost-password"
      ]
    },
    "/about": {
      "filePath": "about.lazy.tsx"
    },
    "/_app/connectivity": {
      "filePath": "_app/connectivity.tsx",
      "parent": "/_app"
    },
    "/_app/dashboard": {
      "filePath": "_app/dashboard.tsx",
      "parent": "/_app"
    },
    "/_app/fields": {
      "filePath": "_app/fields.tsx",
      "parent": "/_app"
    },
    "/_app/map": {
      "filePath": "_app/map.tsx",
      "parent": "/_app"
    },
    "/_app/prescriptions": {
      "filePath": "_app/prescriptions.tsx",
      "parent": "/_app"
    },
    "/_app/support": {
      "filePath": "_app/support.tsx",
      "parent": "/_app"
    },
    "/_auth/login": {
      "filePath": "_auth/login.tsx",
      "parent": "/_auth"
    },
    "/_auth/reset-password": {
      "filePath": "_auth/reset-password.tsx",
      "parent": "/_auth"
    },
    "/_app/profile": {
      "filePath": "_app/profile.lazy.tsx",
      "parent": "/_app"
    },
    "/_app/settings": {
      "filePath": "_app/settings.lazy.tsx",
      "parent": "/_app"
    },
    "/_auth/lost-password": {
      "filePath": "_auth/lost-password.lazy.tsx",
      "parent": "/_auth"
    }
  }
}
ROUTE_MANIFEST_END */
